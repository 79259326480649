import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  scaniaBlack,
  scaniaRed50,
  scaniaRedNegative,
  scaniaGrey50,
  scaniaBlueNegative,
  scaniaYellow,
} from "@src/constants/colors";
import { List, Grid } from "@src/components";
import ErrorIcon from "@src/components/icons/Error";
import InfoIcon from "../icons/Info";
import WarningIcon from "../icons/Warning";

export type BannerProps = {
  severity: string;
  toastHeaderText: string;
  toastBodyText?: string;
  toastSubBodyText?: string;
  testid: string;
};

const useStyles = makeStyles(
  {
    root: {
      color: scaniaBlack,
      flexDirection: "column",
      minWidth: "360px",
      padding: "0px",
      overflow: "auto",
    },

    toastContainer: {
      borderRadius: "4px",
      color: "rgba(13, 15, 19, 0.87)",
      display: "flex",
      background: scaniaGrey50,
      paddingBottom: "10px",
    },
    toastContent: {
      padding: "15px 35px 0px 0px",
      alignItems: "center",
      fontWeight: 400,
      "@media (max-width: 400px)": {
        paddingRight: "20px",
      },
    },
    toastHeader: {
      fontWeight: 700,
    },
    icon: {
      padding: "17px 17px 0px 17px",
    },
    info: {
      borderLeft: `4px solid ${scaniaBlueNegative}`,
    },
    error: {
      background: scaniaRed50,
      borderLeft: `4px solid ${scaniaRedNegative}`,
    },
    warning: {
      borderLeft: `4px solid ${scaniaYellow}`,
    },
  },
  { index: 1 }
);

const ToastBanner: React.FC<BannerProps> = ({
  severity,
  toastHeaderText,
  toastBodyText,
  toastSubBodyText,
  testid,
}) => {
  const {
    root,
    toastContainer,
    toastContent,
    toastHeader,
    icon,
    info,
    error,
    warning,
  } = useStyles();

  let toastWrapper = null;
  let toastIcon = null;

  switch (severity) {
    case "info":
      toastWrapper = clsx(toastContainer, info);
      toastIcon = <InfoIcon />;
      break;
    case "warning":
      toastWrapper = clsx(toastContainer, warning);
      toastIcon = <WarningIcon />;
      break;
    default:
      toastWrapper = clsx(toastContainer, error);
      toastIcon = <ErrorIcon />;
  }

  return (
    <List className={root}>
      <div className={toastWrapper} data-testid={testid}>
        <div className={icon}>{toastIcon}</div>

        <div className={toastContent}>
          <span className={toastHeader}>{toastHeaderText}</span>
          <Grid container>
            <Grid item xs={12}>
              {toastBodyText && <span>{toastBodyText}</span>}
            </Grid>
            <Grid item xs={12}>
              {toastSubBodyText && <span>{toastSubBodyText}</span>}
            </Grid>
          </Grid>
        </div>
      </div>
    </List>
  );
};

export default ToastBanner;
