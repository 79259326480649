import React from "react";
import { Box, Button, ButtonProps } from "@src/components";

const VehicleActionButton: React.FC<ButtonProps> = ({
  color,
  children,
  ...rest
}) => {
  return (
    <Box mt={2}>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        fullWidth
        variant="contained"
        color={color || "primary"}
      >
        {children}
      </Button>
    </Box>
  );
};

export default VehicleActionButton;
