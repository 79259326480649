import { makeStyles, Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { scaniaRedNegative, scaniaGreen400 } from "@src/constants/colors";
import Events from "../../helpers/events";

const useStyles = makeStyles({
  root: {
    zIndex: 50, // Places the toaster above the vehicle list container
  },
  toastContainer: {
    maxWidth: "182px",
    minWidth: "140px",
    height: "40px",
    background: "white",
    borderRadius: "4px",
    boxShadow: `rgba(0, 0, 0, 0.35) 5px 5px 10px;`,
  },
  toastIcon: {
    width: "5px",
    height: "40px",
    float: "left",
  },
  toastContent: {
    paddingTop: 12,
    paddingLeft: 10,
  },
});

const ToastContainer = () => {
  const { root, toastContent, toastContainer, toastIcon } = useStyles();
  const [content, setContent] = useState(null);
  const open = !!content;
  const { message, toastClass } = content || {};

  const reset = () => setContent(null);

  const callback = ({ detail }) => {
    setContent({ message: detail.message, toastClass: detail.toastClass });
  };

  useEffect(() => {
    window.addEventListener(Events.TOAST, callback);
    return () => window.removeEventListener(Events.TOAST, callback);
  }, []);

  return (
    <Snackbar
      className={root}
      open={open}
      autoHideDuration={5000}
      onClose={reset}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="cnd-toast"
    >
      <div className={toastContainer}>
        <div className={toastIcon}>
          <svg
            width="5"
            height="40"
            viewBox="0 0 5 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
          >
            <path
              d="M824.631 1066.08h-48.54c-85.727 0-155.327 5.92-155.327 13.21v111.65c0 7.66 73.08 13.88 163.094 13.88h40.773v-138.74Z"
              transform="matrix(.02453 0 0 .28832 -15.225 -307.369)"
              fill={
                toastClass === `warning` ? scaniaRedNegative : scaniaGreen400
              }
            />
          </svg>
        </div>

        <div className={toastContent}>{message}</div>
      </div>
    </Snackbar>
  );
};

export default ToastContainer;
