import { StateNodeConfig, assign } from "xstate";
import { map } from "rxjs/operators";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendHorn, getControlStates } from "@ats/graphql";

export interface IdentifyStateSchema {
  states: {
    sendCommand: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    pending: {};
    done: {};
    failed: {};
  };
}

export const sendIdentify = () => async (context: VehicleContext) =>
  sendHorn(context.externalEquipmentReference);

export const subscribeToControlState = () => async (context: VehicleContext) =>
  getControlStates({
    controlId: context.commandId,
  }).pipe(
    map((value: any) => {
      const result = {
        type: value.errors !== undefined ? "COMMAND_SUCCESS" : "COMMAND_FAILED",
        data: { commandId: context.commandId },
      };
      return result;
    }) as any
  );

const IdentifyStates: StateNodeConfig<
  VehicleContext,
  IdentifyStateSchema,
  VehicleEvent
> = {
  initial: "sendCommand",
  states: {
    sendCommand: {
      invoke: {
        src: "sendIdentify",
        onDone: {
          target: "pending",
          actions: assign({
            commandId: (_context, { data }) => data.data.horn.id,
          }),
        },
        onError: {
          target: "failed",
          actions: (_context, event) => {
            // eslint-disable-next-line no-console
            console.error("Error sending stand horn command", event);
          },
        },
      },
    },
    pending: {
      invoke: {
        src: "subscribeToControlState",
      },
      on: {
        COMMAND_SUCCESS: {
          target: "done",
          cond: "commandSuccessGuard",
        },
        COMMAND_FAILED: {
          target: "failed",
          cond: "commandFailedGuard",
        },
      },
    },
    done: {
      type: "final",
    },
    failed: {
      on: {
        COMMAND_IDENTIFY: "sendCommand",
      },
    },
  },
};

export default IdentifyStates;
