import React from "react";

import {
  Box,
  Icons,
  IconButton,
  Typography,
  Divider,
  Slide,
} from "@src/components";

type VehicleActionProps = {
  title: string;
  open: boolean;
  done: () => void;
};

const VehicleAction: React.FC<VehicleActionProps> = ({
  title,
  open,
  done,
  children,
}) => {
  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box position="absolute" top="0" bottom="0" left="0" right="0">
        <Box
          bgcolor="background.paper"
          height={1}
          px={2}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
          >
            <Typography variant="h3" color="primary">
              {title}
            </Typography>
            <IconButton edge="end" onClick={done}>
              <Icons.ExpandMore />
            </IconButton>
          </Box>
          <Divider />
          <Box
            py={2}
            flex="auto"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

export default VehicleAction;
