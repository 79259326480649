import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements, addTheme } from "@scania/components";
import { theme as scania } from "@scania/theme-light";
import App from "@src/App";
// import { inspect } from "@xstate/inspect";
import { unregister } from "@src/serviceWorkerRegistration";
import { setLocalization, parseLocalization } from "./i18n/i18nSetup";

/*
inspect({
  iframe: false, // open in new window
});
*/

(async () => {
  setLocalization(await parseLocalization(window.location.search));

  ReactDOM.render(<App />, document.getElementById("root"));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

defineCustomElements();
addTheme(scania);
