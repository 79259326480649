import React from "react";
import { isEmpty } from "lodash";
import { initLog, LogClient } from "@ats/graphql";
import Providers from "@src/providers/Providers";
import Router from "@src/router/Router";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakClient from "./keycloakclient";
import PrivateRoute from "./PrivateRoute";
import environment from "./config/environment";
import { currentAccessToken } from "./components/Model/observables";

require("./debug"); // Nothing to import, just need it to be included

initLog(LogClient.DICE);

// eslint-disable-next-line no-console
console.log(
  "DICE code version: ",
  environment.buildInfo ? environment.buildInfo.lastCommitHash : "unknown"
);

const initOptions = {
  onLoad: "login-required",
  pkceMethod: "S256", // use pkce
  flow: "standard", // code
  enableLogging: false,
  checkLoginIframe: true,
};
const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={initOptions}
      onTokens={({ token }) => {
        window.accessToken = token;
        window.isAuthenticated = true;
        // Track token changes in an observable to allow for re-creating subscriptions
        currentAccessToken.next(token && !isEmpty(token) ? token : null);
      }}
    >
      <PrivateRoute>
        <Providers>
          <Router />
        </Providers>
      </PrivateRoute>
    </ReactKeycloakProvider>
  );
};

export default App;
