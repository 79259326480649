import React from "react";
import { ICommonIconProps } from "./CommonIconProps";

export const InfoIcon = ({ className, style, testid }: ICommonIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      data-testid={testid}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.008 2.50244C5.85709 2.50244 2.49213 5.8674 2.49213 10.0183C2.49213 14.1692 5.85709 17.5341 10.008 17.5341C14.1589 17.5341 17.5238 14.1692 17.5238 10.0183C17.5238 5.8674 14.1589 2.50244 10.008 2.50244ZM1.24213 10.0183C1.24213 5.17705 5.16673 1.25244 10.008 1.25244C14.8492 1.25244 18.7738 5.17705 18.7738 10.0183C18.7738 14.8595 14.8492 18.7841 10.008 18.7841C5.16673 18.7841 1.24213 14.8595 1.24213 10.0183Z"
        fill="#2B70D3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0156 8.82889C10.3607 8.82889 10.6406 9.10871 10.6406 9.45389L10.6406 12.9635C10.6406 13.3087 10.3607 13.5885 10.0156 13.5885C9.67039 13.5885 9.39056 13.3087 9.39056 12.9635L9.39056 9.45389C9.39056 9.10871 9.67039 8.82889 10.0156 8.82889Z"
        fill="#2B70D3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0156 6.44809C10.3607 6.44809 10.6406 6.72791 10.6406 7.07309L10.6406 7.20878C10.6406 7.55396 10.3607 7.83378 10.0156 7.83378C9.67039 7.83378 9.39056 7.55396 9.39056 7.20878L9.39056 7.07309C9.39056 6.72791 9.67039 6.44809 10.0156 6.44809Z"
        fill="#2B70D3"
      />
    </svg>
  );
};
export default InfoIcon;
