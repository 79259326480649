import { createMachine, State, Interpreter, send } from "xstate";
import {
  VehiclesQuery_allEquipment as AllVehicle,
  VehiclesQuery,
  VehiclesQuery_allEquipment_status as VehicleStatus,
} from "@src/graphql/schemaTypes";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";

// The events that the machine handles
export type FetchVehiclesEvent = {
  type: "done.invoke.sendVehiclesQuery";
  data: {
    data: VehiclesQuery;
  };
};

export type VehiclesEvent =
  | {
      type: "RETRY_VEHICLES_ACTION";
    }
  | {
      type: "VEHICLE_STATUS_UPDATE";
      externalEquipmentReference: VehicleStatus["externalEquipmentReference"];
    }
  | FetchVehiclesEvent;

export type VehiclesStateSchema = {
  states: {
    fetching: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    success: {
      states: {
        onDone: {};
      };
    };
    failed: {};
  };
};

// The context (extended state) of the machine

export interface VehiclesContext {
  vehicleActors?: VehicleService[]; // Is empty array before actors created
  vehicles?: AllVehicle[];
}

// The hierarchical (recursive) schema for the states
export type VehiclesTypeState =
  | {
      value: "fetching" | "failed";
      context: VehiclesContext & {
        vehicleActors: undefined;
        vehicles: undefined;
      };
    }
  | {
      value: "success";
      context: VehiclesContext & {
        vehicleActors: VehicleService[];
        vehicles: AllVehicle[];
      };
    };

export type VehiclesState = State<
  VehiclesContext,
  VehiclesEvent,
  VehiclesStateSchema,
  VehiclesTypeState
>;

export type VehiclesService = Interpreter<
  VehiclesContext,
  VehiclesStateSchema,
  VehiclesEvent,
  VehiclesTypeState
>;

export const vehiclesMachine = createMachine<
  VehiclesContext,
  VehiclesEvent,
  VehiclesTypeState
>(
  {
    id: "vehicles",
    initial: "fetching",
    states: {
      fetching: {
        invoke: {
          src: "sendVehiclesQuery",
          onDone: {
            target: "success",
            actions: "addVehicles",
          },
          onError: "failed",
        },
      },
      success: {
        invoke: {
          src: "subscribeToVehicleStatus",
          onDone: {},
        },
        on: {
          VEHICLE_STATUS_UPDATE: {
            actions: "updateVehicleStatus",
          },
        },
      },
      failed: {
        on: {
          RETRY_VEHICLES_ACTION: "fetching",
        },
      },
    },
  },
  {
    actions: {
      updateVehicleStatus: send(
        (_context, event) => ({
          type: "VEHICLE_STATUS",
          data: event,
        }),
        {
          to: (_context, event) =>
            event.type === "VEHICLE_STATUS_UPDATE"
              ? `vehicle-${event.externalEquipmentReference}`
              : "",
        }
      ),
    },
  }
);

export const getVehicleRef = (vehicleId: string, state: VehiclesState) => {
  const actors = state.context.vehicleActors;

  if (actors === undefined) {
    return undefined;
  }

  return actors.find((actor) => actor.id === `vehicle-${vehicleId}`);
};

export default vehiclesMachine;
