import React, { useState, useEffect, useCallback } from "react";

import { isEqual } from "lodash";
import { t } from "ttag";
import { Lab, Box, SwitchSlider } from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";

type ReleasePersonalStandDownStates = {
  sendCommand: boolean;
  pending: boolean;
  success: boolean;
  failed: boolean;
};

type ReleasePersonalStandDownActions = {
  releasePersonalStandDown: () => void;
};

export const getReleasePersonalStandDownStates = (
  state: VehicleState
): ReleasePersonalStandDownStates => {
  return {
    sendCommand: state.matches(
      "connection.online.command.releasePersonalStandDown.sendCommand"
    ),
    pending: state.matches(
      "connection.online.command.releasePersonalStandDown.pending"
    ),
    success: state.matches(
      "connection.online.command.releasePersonalStandDown.success"
    ),
    failed: state.matches(
      "connection.online.command.releasePersonalStandDown.failed"
    ),
  };
};

export const getReleasePersonalStandDownActions = (
  send: (event: VehicleEvent) => void
): ReleasePersonalStandDownActions => {
  return {
    releasePersonalStandDown: () =>
      send({ type: "COMMAND_RELEASE_PERSONAL_STAND_DOWN" }),
  };
};

const getReleasePersonalStandDownStatus = ({
  sendCommand,
  pending,
  success,
}: ReleasePersonalStandDownStates) => {
  if (sendCommand) {
    return t`Sending`;
  }

  if (pending) {
    return t`Wait`;
  }

  if (success) {
    return t`Success`;
  }

  return "";
};

type ReleasePersonalStandDownProps = {
  states: ReleasePersonalStandDownStates;
  actions: ReleasePersonalStandDownActions;
};

const ReleasePersonalStandDown = React.memo(
  ({ states, actions }: ReleasePersonalStandDownProps) => {
    const [checked, setChecked] = useState(false);
    const { failed } = states;
    const callback = useCallback(() => {
      setChecked(true);
      actions.releasePersonalStandDown();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (failed) {
        setChecked(false);
      }
    }, [failed]);

    const handleChecked = callback;

    return (
      <Box position="relative">
        {failed && (
          <Box position="absolute" bottom="100%" width={1}>
            <Lab.Alert severity="error">
              <Lab.AlertTitle>{t`Unable to leave safe state`}</Lab.AlertTitle>
            </Lab.Alert>
          </Box>
        )}
        <SwitchSlider
          leftLabel={t`Leave Safe State`}
          rightLabel={getReleasePersonalStandDownStatus(states)}
          checked={checked}
          onChecked={handleChecked}
          direction="rtl"
        />
      </Box>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.states, nextProps.states)
);

export default ReleasePersonalStandDown;
