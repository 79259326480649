import React from "react";
import { makeStyles } from "@material-ui/core";
import { VehiclesQuery_allEquipment as allVehicle } from "@src/graphql/schemaTypes";
import { useService } from "@xstate/react";
import { getVehicleRef } from "@src/machines/vehicles/vehicles.machine";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@src/components";
import ConnectedVehicleIcon from "@src/containers/Vehicle/icons/ConnectedVehicleIcon.container";
import LockIcon from "@src/containers/Vehicle/icons/LockIcon.container";
import { VehiclesViewProps } from "./Vehicles.container";
import VehicleIdentification from "./VehicleIdentification";
import ToastContainer from "./Toast.container";

const useStyles = makeStyles(
  {
    noData: {
      paddingTop: "24px",
      paddingLeft: "24px",
    },
  },
  { index: 1 }
);

export const sortByDisplayName = (vehicles: allVehicle[]) =>
  [...vehicles].sort((a: allVehicle, b: allVehicle) =>
    a.displayName.localeCompare(b.displayName, undefined, {
      sensitivity: "base",
    })
  );

interface IVehicleListItemProps {
  onVehicleClick: VehiclesViewProps["onVehicleClick"];
  vehicle: allVehicle;
  divider: boolean;
  vehicleRef: VehicleService;
}

const VehicleListItem: React.FC<IVehicleListItemProps> = ({
  onVehicleClick,
  vehicleRef,
  vehicle,
  divider,
}) => {
  const [vehicleState] = useService(vehicleRef);
  const { data } = vehicleState.context;
  const { status } = data;

  return (
    <ListItem
      button
      divider={divider}
      onClick={() => onVehicleClick(vehicle.id)}
      disabled={status === undefined}
    >
      <ListItemAvatar>
        <Avatar>
          {vehicleRef && (
            <ConnectedVehicleIcon size={38} vehicleRef={vehicleRef} />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText disableTypography>{vehicle.displayName}</ListItemText>

      <ListItemIcon>
        {vehicleRef && <LockIcon vehicleRef={vehicleRef} />}
      </ListItemIcon>

      <ListItemSecondaryAction>
        <VehicleIdentification
          externalEquipmentReference={vehicle.externalEquipmentReference}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const VehiclesList: React.FC<VehiclesViewProps> = ({
  vehiclesState,
  onVehicleClick,
  uiState,
}) => {
  const { noData } = useStyles();
  const { vehicles } = vehiclesState.context;
  const { siteId } = uiState.context;
  const filteredVehicles = vehicles?.filter(
    (vehicle) => vehicle.areaId === siteId
  );

  return filteredVehicles && filteredVehicles.length > 0 ? (
    <>
      <List>
        {sortByDisplayName(filteredVehicles).map(
          (vehicle: allVehicle, index) => {
            const vehicleRef = getVehicleRef(vehicle.id, vehiclesState);
            return vehicleRef ? (
              <VehicleListItem
                vehicleRef={vehicleRef}
                divider={index !== filteredVehicles.length}
                key={vehicle.id}
                vehicle={vehicle}
                onVehicleClick={onVehicleClick}
              />
            ) : null;
          }
        )}
      </List>
      <ToastContainer />
    </>
  ) : (
    <>
      <div className={noData}> No vehicle to select</div>
    </>
  );
};

export default VehiclesList;
