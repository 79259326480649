import { AbilityBuilder, Ability, createAliasResolver } from "@casl/ability";

import { UiEvent } from "@src/machines/ui/ui.machine";
import subjectTypeFromGraphql from "./subjectTypeFromGraphql";

export type CrudActions = "CREATE" | "READ" | "UPDATE" | "DELETE";
export type AppAbility = [
  CrudActions | UiEvent["type"] | "READ_VEHICLE" | "IDENTIFY",
  "Vehicle"
];
export type UserRole = "loadingOperator" | "fieldOperator" | "dumpingOperator";

const ability = (role?: UserRole) => {
  const { can, build } = new AbilityBuilder<Ability<AppAbility>>();

  const resolveAction = createAliasResolver({
    READ_VEHICLE: ["READ", "VIEW_VEHICLES", "VIEW_VEHICLE", "SELECT_VEHICLE"],
  }) as any;

  if (role === "loadingOperator") {
    can("READ_VEHICLE", "Vehicle");
    can("IDENTIFY", "Vehicle");
    can("PERSONAL_STAND_DOWN", "Vehicle");
    can("RELEASE_TO_CONTINUE", "Vehicle");
    can("CALL_TO_LOAD_POSITION", "Vehicle");
  }

  if (role === "dumpingOperator") {
    can("READ_VEHICLE", "Vehicle");
  }

  if (role === "fieldOperator") {
    can("READ_VEHICLE", "Vehicle");
    can("IDENTIFY", "Vehicle");
    can("IN_VEHICLE_AND_PERSONAL_STAND_DOWN", "Vehicle");
    can("RELEASE_PERSONAL_STAND_DOWN", "Vehicle");
    can("DISPATCH_VEHICLE", "Vehicle");
    can("PERSONAL_OWNERSHIP", "Vehicle");
    can("RELEASE_VEHICLE", "Vehicle");
  }

  return build({
    detectSubjectType: subjectTypeFromGraphql,
    resolveAction,
  });
};

export default ability;
