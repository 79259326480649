import environment from "./config/environment";

interface Props {
  enableDevTools: boolean;
}

export const config: Props = {
  enableDevTools: environment.env !== "prod" || environment.devTools === true,
};

const APPSYNC_ENDPOINT = environment.appSync.endpoint;
const APPSYNC_REGION = environment.appSync.region;
const FG_CLIENT_ID = environment.keycloak.clientId;
const FG_URL = environment.keycloak.url;

export const appSyncConfiguration = {
  appSyncEndpoint: APPSYNC_ENDPOINT,
  appSyncRegion: APPSYNC_REGION,
};

export const federationGatewayConfiguration = {
  url: FG_URL,
  clientId: FG_CLIENT_ID,
};
