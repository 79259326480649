import { useContext } from "react";
import { useService } from "@xstate/react";
import { UiContext } from "@src/providers/Ui.provider";

const useUiService = () => {
  const context = useContext(UiContext);

  return useService(context.uiService);
};

export default useUiService;
