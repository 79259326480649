import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
// import { sendStartLoading as sendStandDown } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface PersonalStandDownStateSchema {
  states: {
    sendCommand: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    pending: {};
    success: {};
    failed: {};
  };
}

// export const sendStandDownPersonal = () => async (context: VehicleContext) => {
//   return sendStandDown(context.externalEquipmentReference);
// };

const StandDownStates: StateNodeConfig<
  VehicleContext,
  PersonalStandDownStateSchema,
  VehicleEvent
> = {
  id: "standDown",
  initial: "sendCommand",
  states: {
    sendCommand: {
      invoke: {
        src: "sendStandDownPersonal",
        onDone: {
          target: "pending",
        },
        onError: {
          target: "failed",
          actions: (_context, event) => {
            // eslint-disable-next-line no-console
            console.error("Error sending stand down command", event);
          },
        },
      },
    },
    pending: {
      after: {
        30000: "failed",
      },
      always: {
        target: "success",
        cond: "personalStandDown",
      },
    },
    success: {},
    failed: {},
  },
};

export default StandDownStates;
