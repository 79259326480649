import { RouteProps } from "react-router-dom";

import { Authenticated } from "@src/views";

export const ROUTE_ROOT = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGIN_CALLBACK = "/logincallback";

export const protectedRoutes: RouteProps[] = [
  {
    component: Authenticated,
    exact: true,
    path: ROUTE_ROOT,
  },
];

export const routes: RouteProps[] = [];
