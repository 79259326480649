import { StateNodeConfig, assign, InvokeCreator } from "xstate";
import { merge } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendMission, Mission, getMissionStates } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface DispatchStateSchema {
  states: {
    dispatching: {
      states: {
        sendCommand: {
          states: {
            onDone: {};
            onError: {};
          };
        };
        success: {};
        failed: {};
        done: {};
      };
    };
    pending: {
      states: {
        sendCommand: {
          states: {
            onDone: {};
            onError: {};
          };
        };
        success: {};
        timeout: {};
        failed: {};
        rejected: {};
        done: {};
      };
    };
    done: {};
  };
}

type DispatchEvent = {
  type: "onDone";
  data: Mission;
};

export const sendExecuteActionList = (): InvokeCreator<
  VehicleContext,
  VehicleEvent,
  any
> => async (context: VehicleContext, event: VehicleEvent) => {
  return sendMission({
    externalEquipmentReference: context.externalEquipmentReference,
    mapVersion: context.data.status?.mapVersion || "",
    actionListId:
      event.type === "SELECT_DISPATCH_VEHICLE" ? event.actionListId : "",
    actions: event.type === "SELECT_DISPATCH_VEHICLE" ? event.actions : [],
  });
};

export const subscribeToMissionState = () => async (
  context: VehicleContext
) => {
  const subscription = getMissionStates({
    missionId: context.missionId,
  });

  const successOrFailed = subscription
    .pipe(
      filter(({ data }) => {
        return (
          data &&
          (data.missionState.accepted ||
            data.missionState.executing ||
            data.missionState.done)
        );
      }) as any
    )
    .pipe(
      map((value: any) => ({
        type: value.errors ? "FAILED" : "SUCCESS",
      })) as any
    );

  const rejected = subscription
    .pipe(
      filter(({ data }) => {
        return !!data && !!data.missionState.rejected;
      }) as any
    )
    .pipe(
      map(() => ({
        type: "REJECTED",
      })) as any
    );

  return merge(successOrFailed, rejected) as any;
};

const DispatchStates: StateNodeConfig<
  VehicleContext,
  DispatchStateSchema,
  VehicleEvent
> = {
  id: "dispatch",
  initial: "dispatching",
  states: {
    dispatching: {
      initial: "sendCommand",
      states: {
        sendCommand: {
          invoke: {
            src: "sendExecuteActionList",
            onDone: {
              target: "success",
              actions: assign<VehicleContext, DispatchEvent>({
                missionId: (_context, event) => event.data.id,
              }) as any, // https://github.com/davidkpiano/xstate/issues/1198,
            },
            onError: {
              target: "failed",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error("Error sending execute action command", event);
              },
            },
          },
        },
        success: {
          always: "#dispatch.pending",
        },
        failed: {
          on: {
            RETRY_VEHICLE_ACTION: "sendCommand",
            CONFIRM_ERROR_VEHICLE_ACTION: "done",
          },
        },
        done: {
          type: "final",
        },
      },
      onDone: "done",
    },
    pending: {
      initial: "sendCommand",
      states: {
        sendCommand: {
          invoke: {
            src: "subscribeToMissionState",
            onDone: {
              target: "success",
            },
            onError: {
              target: "failed",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error("Error verifying if command was accepted", event);
              },
            },
          },
          after: {
            10000: "timeout",
          },
          on: {
            SUCCESS: "success",
            FAILED: "failed",
            REJECTED: "rejected",
          },
        },
        success: {
          on: {
            DONE_VEHICLE_ACTION: "done",
          },
        },
        failed: {
          on: {
            RETRY_VEHICLE_ACTION: "sendCommand",
            CONFIRM_ERROR_VEHICLE_ACTION: "done",
          },
        },
        rejected: {
          on: {
            CONFIRM_ERROR_VEHICLE_ACTION: "done",
          },
        },
        timeout: {
          always: {
            target: "failed",
            actions: () => {
              // eslint-disable-next-line no-console
              console.error("Timeout while verifying if command was accepted");
            },
          },
        },
        done: {
          type: "final",
        },
      },
      onDone: "done",
    },
    done: {
      type: "final",
    },
  },
};

export default DispatchStates;
