import { appSyncConfiguration } from "./config";

export const api = {
  /* eslint-disable camelcase */
  aws_appsync_authenticationType: "AWS_LAMBDA",
  aws_appsync_graphqlEndpoint: appSyncConfiguration.appSyncEndpoint,
  aws_appsync_region: appSyncConfiguration.appSyncRegion,
  /* eslint-enable */
};

export const auth = {
  type: "AWS_LAMBDA",
};
