import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from "@src/components";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "@src/themes/default.theme";

const GlobalStyle = createGlobalStyle`
  /* Workaround for 100vh not working correctly on iPhone */
  html, body, #root {
    height: 100%;
  }
  /* Make text unselectable by default, and use the pointer cursor on text to help illustrate that the text cannot be selected. */
  body {
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
`;

const Provider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
);

export default Provider;
