import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import ReleaseToContinueStates, {
  ReleaseToContinueStateSchema,
} from "./releaseToContinue.states";
import dispatchStates, { DispatchStateSchema } from "./dispatch.states";
import actionListsStates, {
  ActionListsStateSchema,
} from "./actionLists.states";
import personalOwnershipStates, {
  PersonalOwnershipStateSchema,
} from "./personalOwnership.states";
import releasePersonalOwnershipStates, {
  ReleasePersonalOwnershipStateSchema,
} from "./releasePersonalOwnership.states";
import releaseVehicleStates, {
  ReleaseVehicleStateSchema,
} from "./releaseVehicle.states";

// The hierarchical (recursive) schema for the states
export interface InteractionStateSchema {
  states: {
    idle: {};
    releaseToContinue: ReleaseToContinueStateSchema;
    actionLists: ActionListsStateSchema;
    dispatch: DispatchStateSchema;
    personalOwnership: PersonalOwnershipStateSchema;
    releasePersonalOwnership: ReleasePersonalOwnershipStateSchema;
    releaseVehicle: ReleaseVehicleStateSchema;
  };
}

const interactionStates: StateNodeConfig<
  VehicleContext,
  InteractionStateSchema,
  VehicleEvent
> = {
  initial: "idle",
  id: "interaction",
  on: {
    SELECT_PERSONAL_OWNERSHIP: "#interaction.personalOwnership",
    SELECT_RELEASE_PERSONAL_OWNERSHIP: "#interaction.releasePersonalOwnership",
    SELECT_RELEASE_VEHICLE: "#interaction.releaseVehicle",
  },
  states: {
    idle: {
      on: {
        SELECT_RELEASE_TO_CONTINUE: "releaseToContinue",
        SELECT_ACTION_LISTS: "actionLists",
        SELECT_DISPATCH_VEHICLE: "dispatch",
      },
    },
    releaseToContinue: {
      ...ReleaseToContinueStates,
      onDone: "idle",
    },
    actionLists: {
      ...actionListsStates,
      onDone: "idle",
    },
    dispatch: {
      ...dispatchStates,
      onDone: "idle",
    },
    personalOwnership: {
      ...personalOwnershipStates,
      onDone: "idle",
    },
    releasePersonalOwnership: {
      ...releasePersonalOwnershipStates,
      onDone: "idle",
    },
    releaseVehicle: {
      ...releaseVehicleStates,
      onDone: "idle",
    },
  },
};

export default interactionStates;
