import React from "react";

import { Lab, Typography } from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { isEqual } from "lodash";
import { t } from "ttag";

import VehicleActionViewport from "../VehicleActionViewport.component";
import VehicleActionButton from "../VehicleActionButton.component";

type States = {
  releasing: boolean;
  success: boolean;
  failed: boolean;
};

type Actions = {
  releaseToContinue: () => void;
  retry: () => void;
  abort: () => void;
  done: () => void;
};

export const getReleaseToContinueStates = (state: VehicleState): States => ({
  releasing: state.matches(
    "connection.online.interaction.releaseToContinue.releasing"
  ),
  success: state.matches(
    "connection.online.interaction.releaseToContinue.releasing.success"
  ),
  failed: state.matches(
    "connection.online.interaction.releaseToContinue.releasing.failed"
  ),
});

export const getReleaseToContinueActions = (
  send: (event: VehicleEvent) => void,
  done: () => void
): Actions => ({
  releaseToContinue: () => send({ type: "SELECT_RELEASE_TO_CONTINUE" }),
  retry: () => send({ type: "RETRY_VEHICLE_ACTION" }),
  abort: () => {
    send({ type: "CONFIRM_ERROR_VEHICLE_ACTION" });
    done();
  },
  done: () => {
    send({ type: "DONE_VEHICLE_ACTION" });
    done();
  },
});

type Props = {
  states: States;
  actions: Actions;
};

const ReleaseToContinue: React.FC<Props> = React.memo(
  ({ states, actions }) => {
    if (states.failed) {
      return (
        <>
          <VehicleActionViewport>
            <Lab.Alert severity="error">{t`Release failed`}</Lab.Alert>
          </VehicleActionViewport>
          <VehicleActionButton color="secondary" onClick={actions.retry}>
            {t`Retry`}
          </VehicleActionButton>
          <VehicleActionButton color="secondary" onClick={actions.abort}>
            {t`Abort`}
          </VehicleActionButton>
        </>
      );
    }

    if (states.success) {
      return (
        <>
          <VehicleActionViewport>
            <Lab.Alert severity="success">{t`Release success`}</Lab.Alert>
            <Lab.Alert severity="warning">{t`Vehicle can start to move!`}</Lab.Alert>
          </VehicleActionViewport>
          <VehicleActionButton color="primary" onClick={actions.done}>
            {t`Done`}
          </VehicleActionButton>
        </>
      );
    }

    return (
      <>
        <VehicleActionViewport>
          <Typography paragraph>
            {t`Send request to release vehicle lock and personal lock for this vehicle. This will make the vehicle unsafe to approach and can start to move.`}
          </Typography>
        </VehicleActionViewport>
        <VehicleActionButton
          onClick={actions.releaseToContinue}
          disabled={states.releasing}
          loading={states.releasing}
        >
          {t`Send releases command`}
        </VehicleActionButton>
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.states, nextProps.states)
);

export default ReleaseToContinue;
