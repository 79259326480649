import React from "react";
import { getVehicleRef } from "@src/machines/vehicles/vehicles.machine";
import { useVehicles, useUiService } from "@src/hooks";
import Vehicle from "./Vehicle.container";
import LoadingComponent from "../../components/Loading/Loading.component";

const VehicleDataContainer = () => {
  const [vehiclesState] = useVehicles();
  const [uiState, uiSend] = useUiService();
  const { vehicleId } = uiState.context;

  const isFetching = vehiclesState.value === "fetching";

  if (!vehicleId || isFetching) {
    return <LoadingComponent />;
  }

  const vehicleRef = getVehicleRef(vehicleId, vehiclesState);

  return <Vehicle vehicleRef={vehicleRef} uiSend={uiSend} uiState={uiState} />;
};

export default VehicleDataContainer;
