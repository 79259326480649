import React from "react";

import { Box } from "@src/components";

const VehicleActionViewport: React.FC = ({ children }) => (
  <Box flex="auto" overflow="auto">
    {children}
  </Box>
);

export default VehicleActionViewport;
