import React from "react";
import { isEqual } from "lodash";
import { ConnectedVehicleStatus } from "@oi/components";
import {
  IconTypes,
  AnimationTypes,
} from "@oi/components/dist/Components/UI/ConnectedVehicleStatus/Image";
import {
  AutomationMode,
  VehiclesQuery_allEquipment as Vehicle,
  // VehiclesQuery_allEquipment_status as Status,
  VehiclesQuery_allEquipment_status_releaseConditions as ReleaseConditions,
  OperationalStates,
} from "@src/graphql/schemaTypes";
import { useTheme, CircularProgress } from "@src/components";

export type ConnectedVehicleIconProps = {
  status: Vehicle["status"];
  size: number;
};

const getIcon = (
  // eslint-disable-next-line camelcase
  releaseConditions?: ReleaseConditions[]
): IconTypes => {
  if (releaseConditions && releaseConditions.length) {
    return "locked";
  }

  return "normal";
};

// Update this when we know how to express the animation
const getAnimation = (status: Vehicle["status"]): AnimationTypes => {
  if (!status) {
    return "none";
  }

  if (
    (status.mode === AutomationMode.MODE_AUTONOMOUS ||
      status.mode === AutomationMode.MODE_UNMANNED) &&
    status.speed &&
    status.speed > 0
  ) {
    return "rotating";
  }

  return "none";
};

const ConnectedVehicleIcon = React.memo(
  ({ status, size }: ConnectedVehicleIconProps) => {
    const theme = useTheme();

    // CAV-66818 - Here we would like to treat a status === null, which is NOW the initial state before getting any statuses, differently
    // Idea: We could reserve either undefined OR null for the initial state when we don't expect to have any data, and the other one
    // for the case that an error has occured. But we should also then set a timeout changing the state if we haven't received any data within
    // a certain time (2 seconds?!?). This, though, require the change in Vehicles.provider.tsx where we ignore statuses older than 90 seconds.
    const getColor = (): string => {
      if (!status) {
        return theme.palette.error.main;
      }

      if (
        status.mode ===
          (AutomationMode.MODE_AUTONOMOUS || AutomationMode.MODE_UNMANNED) &&
        status.operationalState !== OperationalStates.OPERATIONAL_STATE_INVALID
      ) {
        return theme.palette.grey[200];
      }

      return theme.palette.error.main;
    };

    // CAV-66818 - to review
    // status === undefined is the initial value before we get anything from the equipmentStatus setup... show a spinner
    if (status === undefined) {
      return <CircularProgress size={24} />;
    }

    return (
      <ConnectedVehicleStatus.Image
        color={getColor()}
        secondaryColor="lightgrey"
        icon={getIcon(status ? status.releaseConditions : undefined)}
        animation={getAnimation(status)}
        size={size}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.status, nextProps.status)
);

export default ConnectedVehicleIcon;
