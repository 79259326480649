import React from "react";
import StoreProvider from "./Store.provider";
import UserProvider from "./User.provider";
import AbilityProvider from "./Ability.provider";
import ThemeProvider from "./Theme.provider";

const Providers: React.FC = ({ children }) => (
  <StoreProvider>
    <UserProvider>
      <AbilityProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </AbilityProvider>
    </UserProvider>
  </StoreProvider>
);

export default Providers;
