import { StateNodeConfig } from "xstate";

import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import personalStandDownStates, {
  PersonalStandDownStateSchema,
} from "./personalStandDown.states";
import releasePersonalStandDownStates, {
  ReleasePersonalStandDownStateSchema,
} from "./releasePersonalStandDown.states";
import identifyStates, { IdentifyStateSchema } from "./identify.states";
import inVehicleAndPersonalStandDownStates, {
  InVehicleAndPersonalStandDownStateSchema,
} from "./inVehicleAndPersonalStandDown.states";

// The hierarchical (recursive) schema for the states
export interface CommandStateSchema {
  states: {
    idle: {};
    personalStandDown: PersonalStandDownStateSchema;
    releasePersonalStandDown: ReleasePersonalStandDownStateSchema;
    identify: IdentifyStateSchema;
    inVehicleAndPersonalStandDown: InVehicleAndPersonalStandDownStateSchema;
  };
}

const CommandStates: StateNodeConfig<
  VehicleContext,
  CommandStateSchema,
  VehicleEvent
> = {
  id: "command",
  initial: "idle",
  on: {
    COMMAND_PERSONAL_STAND_DOWN: "#command.personalStandDown",
    COMMAND_RELEASE_PERSONAL_STAND_DOWN: "#command.releasePersonalStandDown",
    COMMAND_IDENTIFY: "#command.identify",
    COMMAND_IN_VEHICLE_AND_PERSONAL_STAND_DOWN:
      "#command.inVehicleAndPersonalStandDown",
  },
  states: {
    idle: {},
    personalStandDown: {
      ...personalStandDownStates,
    },
    releasePersonalStandDown: {
      ...releasePersonalStandDownStates,
    },
    identify: {
      ...identifyStates,
    },
    inVehicleAndPersonalStandDown: {
      ...inVehicleAndPersonalStandDownStates,
    },
  },
};

export default CommandStates;
