import React from "react";
import {
  /* Vehicles, */ Vehicle,
  Sites,
  Zones /* Queue */,
} from "@src/containers";
import { useUiService } from "@src/hooks";

const Authenticated = () => {
  const [uiState] = useUiService();

  if ((uiState as any).matches("authenticated.sites")) {
    return <Sites />;
  }

  if ((uiState as any).matches("authenticated.zones")) {
    return <Zones />;
  }

  // if ((uiState as any).matches("authenticated.queue")) {
  //   return <Queue />;
  // }

  if ((uiState as any).matches("authenticated.vehicle")) {
    return <Vehicle />;
  }

  return null;
};

export default Authenticated;
