/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { t } from "ttag";
import {
  Backdrop,
  Button,
  CircularProgress,
  Lab,
  Icons,
  Switch,
  FormControlLabel,
  Box,
} from "@src/components";
import { Header } from "@src/containers";
import { EventData } from "xstate";
import {
  VehiclesState,
  VehiclesEvent,
} from "@src/machines/vehicles/vehicles.machine";
import { isEqual } from "lodash";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import VehiclesList from "./VehiclesList.component";
import VehiclesMap from "./VehiclesMap.container";

export type VehiclesViewProps = {
  vehiclesState: VehiclesState;
  onVehicleClick: (id: string) => void;
  uiState: UiState;
};

type VehiclesProps = {
  vehiclesState: VehiclesState;
  vehiclesSend: (
    event: VehiclesEvent,
    payload?: EventData | undefined
  ) => VehiclesState;
  uiState: UiState;
  uiSend: (event: UiEvent) => void;
};

const Vehicles = React.memo(
  ({ vehiclesState, vehiclesSend, uiState, uiSend }: VehiclesProps) => {
    const handleVehicleClick: VehiclesViewProps["onVehicleClick"] = (id) => {
      uiSend({
        type: "SELECT_VEHICLE",
        id,
        queueId: "",
        paddockId: "",
        zoneName: "",
        zoneType: "",
      });
    };

    const handleMapToggle = () =>
      uiSend({
        type: "SET_VEHICLES_VIEW",
        view: uiState.context.vehiclesView === "LIST" ? "MAP" : "LIST",
      });

    const retry = (
      <Button
        color="primary"
        endIcon={<Icons.Replay />}
        onClick={() => vehiclesSend({ type: "RETRY_VEHICLES_ACTION" })}
      >
        {t`Retry loading vehicles`}
      </Button>
    );

    const renderVehiclesView = () => {
      switch (uiState.context.vehiclesView) {
        case "MAP":
          return (
            <VehiclesMap
              vehiclesState={vehiclesState}
              onVehicleClick={handleVehicleClick}
              uiState={uiState}
            />
          );
        case "LIST":
        default:
          return (
            <VehiclesList
              uiState={uiState}
              vehiclesState={vehiclesState}
              onVehicleClick={handleVehicleClick}
            />
          );
      }
    };

    const renderVehicles = () => (
      <>
        <Box textAlign="right" pt={2}>
          <FormControlLabel
            control={
              <Switch
                checked={uiState.context.vehiclesView === "MAP"}
                onChange={handleMapToggle}
              />
            }
            label="Map view"
          />
        </Box>
        {renderVehiclesView()}
      </>
    );

    // eslint-disable-next-line no-unused-vars
    const renderContent = () => {
      if (vehiclesState.matches("fetching")) {
        return (
          <Backdrop open>
            <CircularProgress color="primary" />
          </Backdrop>
        );
      }

      if (vehiclesState.matches("success")) {
        const { vehicles } = vehiclesState.context;

        return vehicles.length ? (
          renderVehicles()
        ) : (
          <Lab.Alert severity="info">{t`There are no vehicles to show`}</Lab.Alert>
        );
      }

      if (vehiclesState.matches("failed")) {
        return (
          <Lab.Alert severity="error" action={retry}>
            {t`Error loading vehicles`}
          </Lab.Alert>
        );
      }

      return (
        <Lab.Alert severity="error" action={retry}>
          {t`Unknown error`}
        </Lab.Alert>
      );
    };

    return (
      <>
        <Header
          text={t`Vehicles`}
          uiState={uiState}
          uiSend={uiSend}
          sites={uiState.context.sites ? uiState.context.sites : undefined}
          siteMenu
        />

        {renderContent()}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.vehiclesState.value === nextProps.vehiclesState.value &&
    isEqual(
      prevProps.vehiclesState.context.vehicles,
      nextProps.vehiclesState.context.vehicles
    ) &&
    prevProps.uiState.value === nextProps.uiState.value
);

export default Vehicles;
