import * as MaterialUiIcons from "@material-ui/icons";
import * as MaterialUiLab from "@material-ui/lab";

import Alert from "./Alert/Alert.component";
import { ButtonProps as CustomButtonProps } from "./Button/Button.component";

export const Icons = { ...MaterialUiIcons };
export const Lab = { ...MaterialUiLab, Alert };

export * from "@material-ui/core";
export { default as Button } from "./Button/Button.component";
export { default as ScaniaLogo } from "./ScaniaLogo/ScaniaLogo.component";
export { default as Select } from "./Select/Select.component";
export { default as SwitchSlider } from "./SwitchSlider/SwitchSlider.component";
export { default as TextField } from "./TextField/TextField.component";
export { default as Loading } from "./Loading/Loading.component";

export type ButtonProps = CustomButtonProps;
