import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";

export interface OfflineStateSchema {
  states: {
    idle: {};
  };
}

const offlineStates: StateNodeConfig<
  VehicleContext,
  OfflineStateSchema,
  VehicleEvent
> = {
  id: "offline",
  initial: "idle",
  states: {
    idle: {},
  },
};

export default offlineStates;
