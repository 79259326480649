import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import online, { OnlineStateSchema } from "./online.states";
import offline, { OfflineStateSchema } from "./offline.states";

export interface ConnectionStateSchema {
  states: {
    online: OnlineStateSchema;
    offline: OfflineStateSchema;
  };
}

const onlineStates: StateNodeConfig<
  VehicleContext,
  ConnectionStateSchema,
  VehicleEvent
> = {
  id: "connection",
  initial: "online",
  states: {
    online,
    offline,
  },
  on: {
    VEHICLE_ONLINE: { target: ".online" },
    VEHICLE_OFFLINE: { target: ".offline" },
  },
};

export default onlineStates;
