import React from "react";

import { SystemName, DispatcherInput } from "@ats/graphql";

import { t } from "ttag";

const CurrentDispatcher: React.FC<{
  dispatcher: DispatcherInput | null;
}> = ({ dispatcher }) => {
  const getCurrentDispatcher = (systemName?: SystemName): string => {
    switch (systemName) {
      case SystemName.ICE_UI:
        return t`ICE UI`;
      case SystemName.DICE_UI:
        return t`DICE UI`;
      case SystemName.WENCO:
        return t`Wenco`;
      default:
        return t`-`;
    }
  };

  return (
    <div data-testid="current-dispatcher">
      {getCurrentDispatcher(dispatcher ? dispatcher.systemName : undefined)}
    </div>
  );
};

export default CurrentDispatcher;
