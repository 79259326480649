import { createMachine, Interpreter, State } from "xstate";
import authenticatedStates, {
  AuthenticatedStateSchema,
  AuthenticatedContext,
  AuthenticatedEvent,
} from "./states/authenticated.state";

export interface UiContext extends AuthenticatedContext {}

type UiTypeState = {
  value: "authenticated";
  context: AuthenticatedContext;
};

type UiStateSchema = {
  states: {
    authenticated: AuthenticatedStateSchema;
  };
};

export type UiEvent = AuthenticatedEvent;

export type UiState = State<UiContext, UiEvent, UiStateSchema, UiTypeState>;

export type UiService = Interpreter<
  UiContext,
  UiStateSchema,
  UiEvent,
  UiTypeState
>;

const UiMachine = createMachine<UiContext, UiEvent, UiTypeState>({
  id: "ui",
  context: {
    vehiclesView: "LIST",
    vehicleId: undefined,
  },
  initial: "authenticated",
  states: {
    authenticated: authenticatedStates,
  },
});

export default UiMachine;
