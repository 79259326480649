import { useState, useEffect } from "react";

const usePersistState = (key: string, defaultValue?: any) => {
  const persistedValue = sessionStorage.getItem(key);
  const [state, setState] = useState(() =>
    persistedValue ? JSON.parse(persistedValue) : defaultValue
  );

  useEffect(() => {
    if (state) {
      sessionStorage.setItem(key, JSON.stringify(state));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, state]);

  return [state, setState];
};

export default usePersistState;
