import React from "react";
import { makeStyles } from "@material-ui/core";
import { Can } from "@src/casl/Can";
import { Card, Box, Grid, Button, Typography } from "@src/components";
import { t } from "ttag";
import { CrudActions } from "@src/casl/ability";

type ContainerProps = {
  action: string;
  disabled: boolean;
  onClick: () => void;
  testId: string;
  labelTitle: string;
  buttonText: string;
  loading?: boolean;
  labelSubTitle?: string;
};

const useStyles = makeStyles({
  grid: {
    fontSize: "1.0em",
    color: "black",
  },
  typography: {
    fontSize: "0.8em",
  },
  button: {
    width: "50%",
  },
});

const VehicleActionButtonContainer: React.FC<ContainerProps> = ({
  action,
  disabled,
  onClick,
  testId,
  labelTitle,
  labelSubTitle,
  buttonText,
  loading,
}) => {
  const { grid, typography, button } = useStyles();
  return (
    <Card>
      <Can I={action as CrudActions} a="Vehicle">
        <Box padding={2}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Grid item xs={12} className={grid}>
                {t`${labelTitle}`}
              </Grid>
              {labelSubTitle && (
                <Grid item xs={12}>
                  <Typography className={typography}>
                    {labelSubTitle}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  className={button}
                  disabled={disabled}
                  onClick={onClick}
                  data-testid={testId}
                  loading={loading}
                >
                  {t`${buttonText}`}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Can>
    </Card>
  );
};

export default VehicleActionButtonContainer;
