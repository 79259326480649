import {
  Mission,
  MissionState,
  Mission_actions as MissionActions,
} from "@ats/graphql";

import IMissionStateExecuting from "./IMissionStateExecuting";
import getExecutingMission from "./getExecutingMission";
import { extractActionLevelTags } from "./extractTagsData";

interface IParameters {
  (
    missions: ReadonlyArray<Mission> | null,
    states: ReadonlyArray<MissionState> | null
  ): boolean;
}
const startLoadingCheck: IParameters = (missions, states) => {
  if (!missions || !states) return false;

  // Get the currently executing mission state
  const executingMissionState: IMissionStateExecuting | null = getExecutingMission(
    states
  );
  if (!executingMissionState) return false;

  // Get the currently executing mission
  const executingMission: Mission | undefined = missions.find(
    ({ id }) => id === executingMissionState.missionId
  );
  if (!executingMission) return false;

  // Get all tags from all actions in the current mission combined to a single array of tags
  const missionActionTags: MissionActions["tags"][] = executingMission.actions.map(
    ({ tags }) => extractActionLevelTags(tags)
  );

  const currentMissionActionTags =
    missionActionTags[executingMissionState.executing.actionIndex];

  return currentMissionActionTags.load === "loading";
};
export default startLoadingCheck;
