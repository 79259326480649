import React from "react";
import { useService } from "@xstate/react";
import { getVehicleRef } from "@src/machines/vehicles/vehicles.machine";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";
import { Container, Box } from "@src/components";
import { Map } from "@oi/components";
import { IPoint } from "@oi/components/dist/Components/UI/Map/Layers/Map.EquipmentLayer";
import { IProps as SelectProps } from "@oi/components/dist/Components/UI/Map/Interactions/Select";

import { VehiclesViewProps } from "./Vehicles.container";

const useVehiclePosition = (vehicleRef: VehicleService) => {
  const [state] = useService(vehicleRef);

  return state.context.data.status?.position;
};

const getData = (vehiclesState: VehiclesViewProps["vehiclesState"]) => {
  const { vehicles } = vehiclesState.context;

  return (
    (vehicles &&
      vehicles.map((vehicle) => {
        const vehicleRef = getVehicleRef(vehicle.id, vehiclesState);
        return {
          id: vehicle.id,
          label: vehicle.displayName,
          iconColor: "red",
          ...(vehicleRef && useVehiclePosition(vehicleRef)),
        } as IPoint;
      })) ||
    []
  );
};

const VehiclesMap: React.FC<VehiclesViewProps> = ({
  vehiclesState,
  onVehicleClick,
}) => {
  const handleSelect: SelectProps["onSelect"] = (event) => {
    const { selected } = event;

    if (selected.length) {
      onVehicleClick(selected[0].getId() as string);
    }
  };

  return (
    <Container>
      <Box position="relative" height="300px">
        <Map.MapComponent uid="vehicles" zoom={16} center={[17.6289, 59.1614]}>
          <Map.Layers.EquipmentLayer
            uid="vehicle"
            data={getData(vehiclesState)}
            labelZoomThreshold={12}
          />
          <Map.Interactions.Select onSelect={handleSelect} />
        </Map.MapComponent>
      </Box>
    </Container>
  );
};

export default VehiclesMap;
