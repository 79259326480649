import React from "react";

import {
  AutomationMode,
  OperationalStates,
  VehiclesQuery_allEquipment_status as VehicleStatus,
} from "@src/graphql/schemaTypes";
import { t } from "ttag";
import { Paper, Typography, Box } from "@src/components";

const VehicleActivity: React.FC<{ status: VehicleStatus }> = ({ status }) => {
  const { mode, operationalState } = status;

  const getActivityValue = (): string => {
    if (
      (mode === AutomationMode.MODE_AUTONOMOUS ||
        mode === AutomationMode.MODE_UNMANNED) &&
      (operationalState === OperationalStates.OPERATIONAL_STATE_IDLE ||
        operationalState ===
          OperationalStates.OPERATIONAL_STATE_IDLE_RESTRICTED)
    ) {
      return t`Idle`;
    }

    if (
      (mode === AutomationMode.MODE_AUTONOMOUS ||
        mode === AutomationMode.MODE_UNMANNED) &&
      (operationalState ===
        OperationalStates.OPERATIONAL_STATE_ENTERING_STAND_DOWN ||
        operationalState ===
          OperationalStates.OPERATIONAL_STATE_LEAVING_STAND_DOWN ||
        operationalState === OperationalStates.OPERATIONAL_STATE_EXECUTING)
    ) {
      return t`Operating`;
    }

    // This is will be deprecated in the future and could then be removed.
    if (
      (mode === AutomationMode.MODE_AUTONOMOUS ||
        mode === AutomationMode.MODE_UNMANNED) &&
      operationalState === OperationalStates.OPERATIONAL_STATE_NORMAL
    ) {
      return t`Active`;
    }

    if (
      (mode === AutomationMode.MODE_AUTONOMOUS ||
        mode === AutomationMode.MODE_UNMANNED) &&
      operationalState === OperationalStates.OPERATIONAL_STATE_STAND_DOWN
    ) {
      return t`Stationary`;
    }

    return "";
  };

  return (
    <Paper>
      <Box padding={1}>
        <Typography variant="h3">{getActivityValue()}</Typography>
      </Box>
    </Paper>
  );
};

export default VehicleActivity;
