import { StateNodeConfig, assign } from "xstate";
import { Area } from "@ats/graphql";

type VEHICLES_VIEW = "LIST" | "MAP";

export interface AuthenticatedContext {
  vehiclesView: VEHICLES_VIEW;
  vehicleId?: string;
  siteId?: string;
  siteName?: string;
  queueId?: string;
  paddockId?: string | null | undefined;
  zoneName?: string;
  zoneType?: string;
  sites?: ReadonlyArray<Area>;
}

export type AuthenticatedTypeState =
  | {
      value: "sites";
      context: AuthenticatedContext;
    }
  | {
      value: "zones";
      context: AuthenticatedContext;
    }
  | {
      value: "queue";
      context: AuthenticatedContext;
    }
  | {
      value: "vehicle";
      context: AuthenticatedContext;
      states:
        | {
            value: "idle";
          }
        | {
            value: "personalStandDown";
          }
        | {
            value: "releaseToContiune";
          }
        | {
            value: "personalOwnership";
          }
        | {
            value: "releaseVehicle";
          };
    };

export type AuthenticatedStateSchema = {
  states: {
    sites: {};
    zones: {};
    queue: {};
    vehicle: {
      states: {
        idle: {};
        dispatch: {};
        releaseToContinue: {};
        personalStandDown: {};
        personalOwnership: {};
        releaseVehicle: {};
      };
    };
  };
};

export type AuthenticatedEvent =
  | { type: "SET_VEHICLES_VIEW"; view: VEHICLES_VIEW }
  | {
      type: "FETCH_SITES";
      sites: ReadonlyArray<Area>;
    }
  | {
      type: "SELECT_SITE";
      id: string;
      siteName: string;
    }
  | {
      type: "SELECT_ZONE";
      queueId: string;
      paddockId: string | null | undefined;
      zoneName: string;
      zoneType: string;
    }
  | {
      type: "SELECT_VEHICLE";
      id: string;
      queueId: string;
      paddockId: string;
      zoneName: string;
      zoneType: string;
    }
  | {
      type:
        | "IN_VEHICLE_AND_PERSONAL_STAND_DOWN"
        | "DISPATCH_VEHICLE"
        | "PERSONAL_STAND_DOWN"
        | "RELEASE_PERSONAL_STAND_DOWN"
        | "VIEW_VEHICLE"
        | "VIEW_VEHICLES"
        | "RELEASE_TO_CONTINUE"
        | "PERSONAL_OWNERSHIP"
        | "RELEASE_VEHICLE"
        | "CALL_TO_LOAD_POSITION"
        | "VIEW_SITES"
        | "VIEW_ZONES";
    };

const authenticatedStates: StateNodeConfig<
  AuthenticatedContext,
  AuthenticatedStateSchema,
  AuthenticatedEvent
> = {
  initial: "sites",
  states: {
    sites: {
      on: {},
    },
    zones: {
      on: {
        SET_VEHICLES_VIEW: {
          actions: assign({
            vehiclesView: (_context, event) => event.view,
          }),
        },
      },
    },
    queue: {
      on: {
        VIEW_ZONES: {
          target: "zones",
        },
      },
    },
    vehicle: {
      initial: "idle",
      states: {
        idle: {
          on: {
            DISPATCH_VEHICLE: "dispatch",
            RELEASE_TO_CONTINUE: "releaseToContinue",
            PERSONAL_STAND_DOWN: "personalStandDown",
            PERSONAL_OWNERSHIP: "personalOwnership",
            RELEASE_VEHICLE: "releaseVehicle",
          },
        },
        dispatch: {},
        releaseToContinue: {},
        personalStandDown: {},
        personalOwnership: {},
        releaseVehicle: {},
      },
      on: {
        VIEW_VEHICLE: ".idle",
      },
    },
  },
  on: {
    VIEW_VEHICLES: {
      target: ".zones",
    },
    SELECT_VEHICLE: {
      target: ".vehicle",
      actions: assign({
        vehicleId: (_context, event) => event.id,
        queueId: (_context, event) => event.queueId,
        paddockId: (_context, event) => event.paddockId,
        zoneName: (_context, event) => event.zoneName,
        zoneType: (_context, event) => event.zoneType,
      }),
    },
    FETCH_SITES: {
      actions: assign({
        sites: (_context, event) => event.sites,
      }),
    },
    SELECT_ZONE: {
      target: ".queue",
      actions: assign({
        queueId: (_context, event) => event.queueId,
        paddockId: (_context, event) => event.paddockId,
        zoneName: (_context, event) => event.zoneName,
        zoneType: (_context, event) => event.zoneType,
      }),
    },
    VIEW_SITES: {
      target: ".sites",
      actions: assign({
        /* eslint-disable no-unused-vars */
        siteId: (_context, event) => "",
        siteName: (_context, event) => "",
        /* eslint-enable no-unused-vars */
      }),
    },
    SELECT_SITE: {
      target: ".zones",
      actions: assign({
        siteId: (_context, event) => event.id,
        siteName: (_context, event) => event.siteName,
      }),
    },
  },
};

export default authenticatedStates;
