import { getLocaleData } from "@oi/components";
import merge from "lodash/merge";
import { addLocale, useLocale as usingLocale } from "ttag";

export const parseLocalization = async (
  search: string
): Promise<[any, string]> => {
  const urlParams = new URLSearchParams(search);
  const locale = urlParams.get("hl") || "en";

  // Get web translations
  let web: any;
  try {
    web = await import(`./__generated__/${locale}.json`);
  } catch (error) {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    console.error(`Language not found: ${locale}`, error);
  }

  // Get components translations
  let components: any;
  try {
    components = await getLocaleData(locale);

    // Add component translations to web translations
    web = merge({}, components, web);
  } catch (error) {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    console.error(`Language not found for Components: ${locale}`, error);
  }

  return [web, locale];
};

export const setLocalization = ([tranlations, locale]: [any, string]): void => {
  if (tranlations && tranlations.default) {
    addLocale(locale, tranlations.default);
    usingLocale(locale);
  }
};
