/*
This is all events used in the app. String enums for better readability when debugging.
*/

enum Event {
  /* Dispatched to show a toast
     Custom = Custom data: { detail: { message: 'Any message string here' }} */
  TOAST = "TOAST",
}

export default Event;
