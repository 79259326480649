import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import interaction, { InteractionStateSchema } from "./interaction.states";
import command, { CommandStateSchema } from "./command.states";

export interface OnlineStateSchema {
  states: {
    interaction: InteractionStateSchema;
    command: CommandStateSchema;
  };
}

const onlineStates: StateNodeConfig<
  VehicleContext,
  OnlineStateSchema,
  VehicleEvent
> = {
  id: "online",
  initial: "interaction",
  type: "parallel",
  states: {
    interaction,
    command,
  },
};

export default onlineStates;
