import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    // If the browser is on-line (has a local connection), try to refresh the token every
    // 2 seconds to recover after being off-line
    const interval = setInterval(() => {
      if (navigator.onLine) {
        try {
          keycloak.updateToken(120);
          // eslint-disable-next-line no-empty, no-unused-vars
        } catch (error) {}
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [initialized, keycloak]);

  return isLoggedIn ? children : null;
};

export default PrivateRoute;
