/* tslint:disable */
/* eslint-disable */
// @generated

// ====================================================
// GraphQL mutation operation: ExecuteActionList
// ====================================================

import { DispatcherInput } from "@ats/graphql";
export interface ExecuteActionList_executeActionList {
  __typename: "Mission";
  id: string;
}

export interface ExecuteActionList {
  /**
   * Execute Mission from Action List
   */
  executeActionList: ExecuteActionList_executeActionList | null;
}

export interface ExecuteActionListVariables {
  externalEquipmentReference: string;
  mapVersion: string;
  actionListId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Horn
// ====================================================

export interface Horn_horn {
  __typename: "Control";
  id: string;
}

export interface Horn {
  /**
   * Send horn command to equipment
   */
  horn: Horn_horn | null;
}

export interface HornVariables {
  externalEquipmentReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PersonalOwnership
// ====================================================

export interface PersonalOwnership_sendPersonalOwnership {
  __typename: "Control";
  id: string;
}

export interface PersonalOwnership {
  /**
   * Send personal ownership to equipment
   */
  sendPersonalOwnership: PersonalOwnership_sendPersonalOwnership | null;
}

export interface PersonalOwnershipVariables {
  externalEquipmentReference: string;
  personalOwnershipType: PersonalOwnershipTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Release
// ====================================================

export interface Release_release {
  __typename: "Control";
  id: string;
}

export interface Release {
  /**
   * Send release command to equipment.
   */
  release: Release_release | null;
}

export interface ReleaseVariables {
  externalEquipmentReference: string;
  releaseType: ReleaseTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReleaseToContinue
// ====================================================

export interface ReleaseToContinue_releaseToContinue {
  __typename: "Control";
  id: string;
}

export interface ReleaseToContinue {
  /**
   * Send release and release personal command to equipment.
   */
  releaseToContinue: ReleaseToContinue_releaseToContinue | null;
}

export interface ReleaseToContinueVariables {
  externalEquipmentReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StandDown
// ====================================================

export interface StandDown_standDown {
  __typename: "Control";
  id: string;
}

export interface StandDown {
  /**
   * Send stand down command to equipment
   */
  standDown: StandDown_standDown | null;
}

export interface StandDownVariables {
  externalEquipmentReference: string;
  standDownType: StandDownTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActionListsQuery
// ====================================================

export interface ActionListsQuery_actionLists_actions_script {
  __typename: "Script";
  displayName: string;
}

export interface ActionListsQuery_actionLists_actions {
  __typename: "ActionListAction";
  actionId: string;
  script: ActionListsQuery_actionLists_actions_script | null;
}

export interface ActionListsQuery_actionLists {
  __typename: "ActionList";
  id: string;
  displayName: string;
  actions: ActionListsQuery_actionLists_actions[];
}

export interface ActionListsQuery {
  /**
   * Get all action lists for a customer and area
   */
  actionLists: ActionListsQuery_actionLists[];
}

export interface ActionListsQueryVariables {
  areaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MissionQuery
// ====================================================

export interface MissionQuery_mission_actions_commands {
  __typename: "MissionCommand";
  name: string;
}

export interface MissionQuery_mission_actions {
  __typename: "MissionAction";
  actionId: string;
  commands: MissionQuery_mission_actions_commands[];
}

export interface MissionQuery_mission_states_executing {
  __typename: "MissionExecuting";
  actionIndex: number;
  commandIndex: number;
}

export interface MissionQuery_mission_states_rejected {
  __typename: "MissionRejected";
  reason: string;
}

export interface MissionQuery_mission_states_failed {
  __typename: "MissionFailed";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionQuery_mission_states_cancelled {
  __typename: "MissionCancelled";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionQuery_mission_states {
  __typename: "MissionState";
  externalEquipmentReference: string;
  timestamp: any;
  accepted: any | null;
  executing: MissionQuery_mission_states_executing | null;
  rejected: MissionQuery_mission_states_rejected | null;
  failed: MissionQuery_mission_states_failed | null;
  cancelled: MissionQuery_mission_states_cancelled | null;
  done: any | null;
}

export interface MissionQuery_mission {
  __typename: "Mission";
  id: string;
  externalEquipmentReference: string;
  createTimestamp: any;
  actions: MissionQuery_mission_actions[];
  states: MissionQuery_mission_states[];
}

export interface MissionQuery {
  /**
   * Get mission by externalEquipmentReference
   */
  mission: MissionQuery_mission;
}

export interface MissionQueryVariables {
  missionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VehiclesQuery
// ====================================================

export interface VehiclesQuery_allEquipment_status_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface VehiclesQuery_allEquipment_status_releaseConditions_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface VehiclesQuery_allEquipment_status_releaseConditions {
  __typename: "ReleaseCondition";
  type: ReleaseConditionTypes;
  owner: string | null;
  staff: VehiclesQuery_allEquipment_status_releaseConditions_staff | null;
}

export interface VehiclesQuery_allEquipment_status_position {
  __typename: "Position";
  altitude: number;
  latitude: number;
  longitude: number;
  heading: number;
}

export interface VehiclesQuery_allEquipment_status {
  __typename: "EquipmentStatus";
  externalEquipmentReference: string;
  timestamp: any;
  mode: AutomationMode;
  mapVersion: string | null;
  speed: number;
  /**
   * Returns safe state for a person
   */
  safeState: SafeState;
  /**
   * Returns if approach routine is possible
   */
  canApproach: boolean;
  /**
   * Returns if leave routine is possible
   */
  canLeave: boolean;
  operationalState: OperationalStates;
  owner: string | null;
  dispatcher: DispatcherInput | null;
  staff: VehiclesQuery_allEquipment_status_staff | null;
  releaseConditions: VehiclesQuery_allEquipment_status_releaseConditions[];
  position: VehiclesQuery_allEquipment_status_position | null;
  /**
   * Returns if personal stand down is applied by the requesting user
   */
  status: string | undefined;
  reason: string | null | undefined;
  personalStandDownByMe: boolean;
}

export interface VehiclesQuery_allEquipment {
  __typename: "Equipment";
  id: string;
  externalEquipmentReference: string;
  displayName: string;
  areaId: string | null;
  status: VehiclesQuery_allEquipment_status | null | undefined;
}

export interface VehiclesQuery {
  /**
   * Get all equipment
   */
  allEquipment: VehiclesQuery_allEquipment[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: VehicleStatusSubscription
// ====================================================

export interface VehicleStatusSubscription_equipmentStatusUpdate_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface VehicleStatusSubscription_equipmentStatusUpdate_releaseConditions_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface VehicleStatusSubscription_equipmentStatusUpdate_releaseConditions {
  __typename: "ReleaseCondition";
  type: ReleaseConditionTypes;
  owner: string | null;
  staff: VehicleStatusSubscription_equipmentStatusUpdate_releaseConditions_staff | null;
}

export interface VehicleStatusSubscription_equipmentStatusUpdate_position {
  __typename: "Position";
  altitude: number;
  latitude: number;
  longitude: number;
  heading: number;
}

export interface VehicleStatusSubscription_equipmentStatusUpdate {
  __typename: "EquipmentStatus";
  externalEquipmentReference: string;
  timestamp: any;
  mode: AutomationMode;
  mapVersion: string | null;
  speed: number;
  /**
   * Returns safe state for a person
   */
  safeState: SafeState;
  /**
   * Returns if approach routine is possible
   */
  canApproach: boolean;
  /**
   * Returns if leave routine is possible
   */
  canLeave: boolean;
  operationalState: OperationalStates;
  owner: string | null;
  dispatcher: DispatcherInput | null;
  staff: VehicleStatusSubscription_equipmentStatusUpdate_staff | null;
  releaseConditions: VehicleStatusSubscription_equipmentStatusUpdate_releaseConditions[];
  position: VehicleStatusSubscription_equipmentStatusUpdate_position | null;
  /**
   * Returns if personal stand down is applied by the requesting user
   */
  personalStandDownByMe: boolean;
}

export interface VehicleStatusSubscription {
  equipmentStatusUpdate: VehicleStatusSubscription_equipmentStatusUpdate | null;
}

export interface VehicleStatusSubscriptionVariables {
  externalEquipmentReference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ControlStateSubscription
// ====================================================

export interface ControlStateSubscription_controlState {
  __typename: "ControlState";
  timestamp: any | null;
  code: string;
  reason: string | null;
}

export interface ControlStateSubscription {
  controlState: ControlStateSubscription_controlState;
}

export interface ControlStateSubscriptionVariables {
  externalEquipmentReference?: string | null;
  controlId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: MissionStateSubscription
// ====================================================

export interface MissionStateSubscription_missionState_executing {
  __typename: "MissionExecuting";
  actionIndex: number;
  commandIndex: number;
}

export interface MissionStateSubscription_missionState_rejected {
  __typename: "MissionRejected";
  reason: string;
}

export interface MissionStateSubscription_missionState_failed {
  __typename: "MissionFailed";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionStateSubscription_missionState_cancelled {
  __typename: "MissionCancelled";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionStateSubscription_missionState {
  __typename: "MissionState";
  missionId: string;
  externalEquipmentReference: string;
  timestamp: any;
  accepted: any | null;
  executing: MissionStateSubscription_missionState_executing | null;
  rejected: MissionStateSubscription_missionState_rejected | null;
  failed: MissionStateSubscription_missionState_failed | null;
  cancelled: MissionStateSubscription_missionState_cancelled | null;
  done: any | null;
}

export interface MissionStateSubscription {
  missionState: MissionStateSubscription_missionState;
}

export interface MissionStateSubscriptionVariables {
  externalEquipmentReference?: string | null;
  missionId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MissionStateFields
// ====================================================

export interface MissionStateFields_executing {
  __typename: "MissionExecuting";
  actionIndex: number;
  commandIndex: number;
}

export interface MissionStateFields_rejected {
  __typename: "MissionRejected";
  reason: string;
}

export interface MissionStateFields_failed {
  __typename: "MissionFailed";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionStateFields_cancelled {
  __typename: "MissionCancelled";
  actionIndex: number;
  commandIndex: number;
  reason: string;
}

export interface MissionStateFields {
  __typename: "MissionState";
  externalEquipmentReference: string;
  timestamp: any;
  accepted: any | null;
  executing: MissionStateFields_executing | null;
  rejected: MissionStateFields_rejected | null;
  failed: MissionStateFields_failed | null;
  cancelled: MissionStateFields_cancelled | null;
  done: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: statusFields
// ====================================================

export interface statusFields_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface statusFields_releaseConditions_staff {
  __typename: "Staff";
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  isMe: boolean;
}

export interface statusFields_releaseConditions {
  __typename: "ReleaseCondition";
  type: ReleaseConditionTypes;
  owner: string | null;
  staff: statusFields_releaseConditions_staff | null;
}

export interface statusFields_position {
  __typename: "Position";
  altitude: number;
  latitude: number;
  longitude: number;
  heading: number;
}

export interface statusFields {
  __typename: "EquipmentStatus";
  externalEquipmentReference: string;
  timestamp: any;
  mode: AutomationMode;
  mapVersion: string | null;
  speed: number;
  /**
   * Returns safe state for a person
   */
  safeState: SafeState;
  /**
   * Returns if approach routine is possible
   */
  canApproach: boolean;
  /**
   * Returns if leave routine is possible
   */
  canLeave: boolean;
  operationalState: OperationalStates;
  owner: string | null;
  dispatcher: DispatcherInput | null;
  staff: statusFields_staff | null;
  releaseConditions: statusFields_releaseConditions[];
  position: statusFields_position | null;
  /**
   * Returns if personal stand down is applied by the requesting user
   */
  personalStandDownByMe: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AutomationMode {
  MODE_ASSISTED = "MODE_ASSISTED",
  MODE_AUTONOMOUS = "MODE_AUTONOMOUS",
  MODE_INVALID = "MODE_INVALID",
  MODE_MANUAL = "MODE_MANUAL",
  MODE_SHADOW = "MODE_SHADOW",
  MODE_UNMANNED = "MODE_UNMANNED",
}

export enum OperationalStates {
  OPERATIONAL_STATE_ENTERING_STAND_DOWN = "OPERATIONAL_STATE_ENTERING_STAND_DOWN",
  OPERATIONAL_STATE_EXECUTING = "OPERATIONAL_STATE_EXECUTING",
  OPERATIONAL_STATE_EXECUTING_RESTRICTED = "OPERATIONAL_STATE_EXECUTING_RESTRICTED",
  OPERATIONAL_STATE_IDLE = "OPERATIONAL_STATE_IDLE",
  OPERATIONAL_STATE_IDLE_RESTRICTED = "OPERATIONAL_STATE_IDLE_RESTRICTED",
  OPERATIONAL_STATE_INVALID = "OPERATIONAL_STATE_INVALID",
  OPERATIONAL_STATE_LEAVING_STAND_DOWN = "OPERATIONAL_STATE_LEAVING_STAND_DOWN",
  OPERATIONAL_STATE_NORMAL = "OPERATIONAL_STATE_NORMAL",
  OPERATIONAL_STATE_STAND_DOWN = "OPERATIONAL_STATE_STAND_DOWN",
}

/**
 * Personal ownership types
 */
export enum PersonalOwnershipTypes {
  PersonalOwnershipLock = "PersonalOwnershipLock",
  PersonalOwnershipUnlock = "PersonalOwnershipUnlock",
}

export enum ReleaseConditionTypes {
  TYPE_AT_POSITION = "TYPE_AT_POSITION",
  TYPE_IMPERSONAL = "TYPE_IMPERSONAL",
  TYPE_INVALID = "TYPE_INVALID",
  TYPE_IN_EQUIPMENT = "TYPE_IN_EQUIPMENT",
  TYPE_PERSONAL = "TYPE_PERSONAL",
}

/**
 * Release types
 */
export enum ReleaseTypes {
  Release = "Release",
  ReleasePersonal = "ReleasePersonal",
}

export enum SafeState {
  CAUTION = "CAUTION",
  NOT_SAFE = "NOT_SAFE",
  SAFE = "SAFE",
}

/**
 * Stand down types
 */
export enum StandDownTypes {
  StandDown = "StandDown",
  StandDownPersonal = "StandDownPersonal",
  StandDownPersonalRequringInEquipmentRelease = "StandDownPersonalRequringInEquipmentRelease",
  StandDownRequiringInEquipmentRelease = "StandDownRequiringInEquipmentRelease",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
