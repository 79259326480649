import React from "react";
import VehiclesProvider from "./Vehicles.provider";
import UiMachineProvider from "./Ui.provider";

const ConnectionProviders: React.FC = ({ children }) => (
  <UiMachineProvider>
    <VehiclesProvider>{children}</VehiclesProvider>
  </UiMachineProvider>
);

export default ConnectionProviders;
