import Keycloak from "keycloak-js";
import { federationGatewayConfiguration } from "./config";

const keycloakConfig = {
  url: federationGatewayConfiguration.url,
  realm: "fg-ext",
  clientId: federationGatewayConfiguration.clientId,
};
const keycloakClient = new Keycloak(keycloakConfig);

export default keycloakClient;
