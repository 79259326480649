import { Machine, Actor, State, assign, Typestate, Interpreter } from "xstate";
import {
  VehiclesQuery_allEquipment as Vehicle,
  VehiclesQuery_allEquipment_status as VehicleStatus,
  ActionListsQuery_actionLists as ActionLists,
  SafeState,
} from "@src/graphql/schemaTypes";
import { MissionActionInput } from "@ats/graphql";
import connection, { ConnectionStateSchema } from "./states/connection.states";
import onlineEvaluation, {
  vehicleOfflineGuard,
  vehicleOnlineGuard,
  OnlineEvaluationSchema,
} from "./states/onlineEvaluation.states";

// The hierarchical (recursive) schema for the states
export interface VehicleStateSchema {
  states: {
    connection: ConnectionStateSchema;
    onlineEvaluation: OnlineEvaluationSchema;
  };
}

// The events that the machine handles
export type InteractionEvent =
  | {
      type:
        | "SELECT_RELEASE_TO_CONTINUE"
        | "SELECT_PERSONAL_STAND_DOWN"
        | "SELECT_ACTION_LISTS"
        | "SELECT_PERSONAL_OWNERSHIP"
        | "SELECT_RELEASE_VEHICLE"
        | "SELECT_RELEASE_PERSONAL_OWNERSHIP";
    }
  | {
      type: "SELECT_DISPATCH_VEHICLE";
      actionListId: string;
      actions: Array<MissionActionInput>;
    };

type ActionEvent = {
  type:
    | "CONFIRM_ERROR_VEHICLE_ACTION"
    | "RETRY_VEHICLE_ACTION"
    | "DONE_VEHICLE_ACTION";
};

type IncomingEvent =
  | {
      type: "VEHICLE_STATUS";
      data: VehicleStatus;
    }
  | { type: "SUCCESS" | "FAILED" | "REJECTED" };

type CommandEvent = {
  type:
    | "COMMAND_PERSONAL_STAND_DOWN"
    | "COMMAND_RELEASE_PERSONAL_STAND_DOWN"
    | "COMMAND_IN_VEHICLE_AND_PERSONAL_STAND_DOWN";
};

type OnlineEvaluationEvent =
  | { type: "VEHICLE_ONLINE" }
  | { type: "VEHICLE_OFFLINE" };

type IdentificationEvent =
  | {
      type: "COMMAND_IDENTIFY";
    }
  | { type: "COMMAND_SUCCESS" | "COMMAND_FAILED"; data: { commandId: string } };

export type VehicleEvent =
  | InteractionEvent
  | ActionEvent
  | IncomingEvent
  | CommandEvent
  | OnlineEvaluationEvent
  | IdentificationEvent;
export interface VehicleContext {
  externalEquipmentReference: string;
  data: Vehicle;
  lastKnownTimestamp: string | null;
  actionLists: ActionLists[];
  missionId: string | null;
  commandId: string | null;
}

export type VehicleTypeState = Typestate<VehicleContext>;

export type VehicleState = State<
  VehicleContext,
  VehicleEvent,
  VehicleStateSchema,
  VehicleTypeState
>;

export type VehicleActor = Actor<VehicleContext, VehicleEvent>;

export type VehicleService = Interpreter<
  VehicleContext,
  VehicleStateSchema,
  VehicleEvent,
  VehicleTypeState
>;

const createVehicleMachine = (name: string) =>
  Machine<VehicleContext, VehicleStateSchema, VehicleEvent>(
    {
      id: `vehicle-${name}`,
      initial: "connection",
      on: {
        VEHICLE_STATUS: {
          actions: ["vehicleStatus"],
        },
      },
      type: "parallel",
      states: {
        connection,
        onlineEvaluation,
      },
    },
    {
      actions: {
        vehicleStatus: assign<VehicleContext, VehicleEvent>({
          lastKnownTimestamp: (context) => context.data.status?.timestamp,
          data: (context, event) => ({
            ...context.data,
            ...(event.type === "VEHICLE_STATUS" && { status: event.data }),
          }),
        }),
      },
      guards: {
        safeToApproachGuard: (context: VehicleContext) =>
          context.data.status
            ? context.data.status.safeState === SafeState.SAFE
            : false,
        personalStandDown: (context: VehicleContext) =>
          !!(context.data.status && context.data.status.personalStandDownByMe),
        noPersonalStandDownGuard: (context: VehicleContext) =>
          !(context.data.status && context.data.status.personalStandDownByMe),
        vehicleOfflineGuard,
        vehicleOnlineGuard,
        commandSuccessGuard: (context, event) =>
          event.type === "COMMAND_SUCCESS" &&
          context.commandId === event.data.commandId,
        commandFailedGuard: (context, event) =>
          event.type === "COMMAND_FAILED" &&
          context.commandId === event.data.commandId,
      },
    }
  );

export default createVehicleMachine;
