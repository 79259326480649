export const scaniaBlue = "#041E42";
export const scaniaWhite = "#FAFAFA";
export const scaniaRed = "#D6001C";
export const scaniaLightGray = "#C8C9C7";
export const scaniaMediumGray = "#97999B";
export const scaniaDarkGray = "#53565A";
export const scaniaBlack = "#2D2926";
export const scaniaRed50 = "#FDF4F5";
export const scaniaRedNegative = "#FF2340";
export const scaniaGrey50 = "#F9FAFB";
export const scaniaBlueNegative = "#2B70D3";

export const scaniaOrange = "#2D2926";
export const scaniaBeige = "#CEB888";
export const scaniaPaleGreen = "#94A596";
export const scaniaGreen = "#2C5234";
export const scaniaGreen400 = "#539E63";
export const scaniaYellow = "#F1C21B";
