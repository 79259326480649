import styled from "styled-components";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = styled(MuiAlert)`
  .MuiAlert-message {
    flex: auto;
  }
`;

export default Alert;
