import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendReleasePersonal as sendReleasePersonalCommand } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface ReleasePersonalStandDownStateSchema {
  states: {
    sendCommand: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    pending: {};
    success: {};
    failed: {};
  };
}

export const sendReleasePersonal = () => async (context: VehicleContext) => {
  return sendReleasePersonalCommand(context.externalEquipmentReference);
};

const ReleaseStates: StateNodeConfig<
  VehicleContext,
  ReleasePersonalStandDownStateSchema,
  VehicleEvent
> = {
  initial: "sendCommand",
  states: {
    sendCommand: {
      invoke: {
        src: "sendReleasePersonal",
        onDone: {
          target: "pending",
        },
        onError: {
          target: "failed",
          actions: (_context, event) => {
            // eslint-disable-next-line no-console
            console.error("Error sending release command", event);
          },
        },
      },
    },
    pending: {
      after: {
        30000: "failed", // Increasing timeout for the control from 10 seconds to 30 seconds
      },
      always: {
        target: "success",
        cond: "noPersonalStandDownGuard",
      },
    },
    success: {},
    failed: {},
  },
};

export default ReleaseStates;
