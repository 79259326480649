import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Lab,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  SwitchSlider,
  FormLabel,
} from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import {
  ActionListsQuery_actionLists as ActionLists,
  AutomationMode,
  OperationalStates,
} from "@src/graphql/schemaTypes";
import { isEqual, omit } from "lodash";
import { t } from "ttag";
import { MissionActionInput } from "@ats/graphql";
import VehicleActionViewport from "../VehicleActionViewport.component";
import VehicleActionButton from "../VehicleActionButton.component";

export type DispatchStates = {
  fetching: boolean;
  fetchingFailed: boolean;
  dispatchingSendCommand: boolean;
  dispatchingFailed: boolean;
  pendingSendCommand: boolean;
  pendingFailed: boolean;
  pendingRejected: boolean;
  pendingSuccess: boolean;
};

export type DispatchActions = {
  selectActionLists: () => void;
  selectDispatchVehicle: (
    actionListId: string,
    actions: Array<MissionActionInput>
  ) => void;
  handleRetry: () => void;
  handleDoneDispatch: () => void;
  handleAbort: () => void;
};

export const getDispatchStates = (state: VehicleState): DispatchStates => {
  return {
    fetching: state.matches(
      "connection.online.interaction.actionLists.fetching"
    ),
    fetchingFailed: state.matches(
      "connection.online.interaction.actionLists.failed"
    ),
    dispatchingSendCommand: state.matches(
      "connection.online.interaction.dispatch.dispatching.sendCommand"
    ),
    dispatchingFailed: state.matches(
      "connection.online.interaction.dispatch.dispatching.failed"
    ),
    pendingSendCommand: state.matches(
      "connection.online.interaction.dispatch.pending.sendCommand"
    ),
    pendingFailed: state.matches(
      "connection.online.interaction.dispatch.pending.failed"
    ),
    pendingRejected: state.matches(
      "connection.online.interaction.dispatch.pending.rejected"
    ),
    pendingSuccess: state.matches(
      "connection.online.interaction.dispatch.pending.success"
    ),
  };
};

export const getDispatchActions = (
  send: (event: VehicleEvent) => void,
  done: () => void
): DispatchActions => {
  return {
    selectActionLists: () => send({ type: "SELECT_ACTION_LISTS" }),
    selectDispatchVehicle: (
      actionListId: string,
      actions: Array<MissionActionInput>
    ) => send({ type: "SELECT_DISPATCH_VEHICLE", actionListId, actions }),
    handleRetry: () => send({ type: "RETRY_VEHICLE_ACTION" }),
    handleDoneDispatch: () => {
      send({ type: "DONE_VEHICLE_ACTION" });
      done();
    },
    handleAbort: () => {
      send({ type: "CONFIRM_ERROR_VEHICLE_ACTION" });
      done();
    },
  };
};

const getDispatchStatus = ({
  dispatchingSendCommand,
  pendingSendCommand,
  pendingSuccess,
}: DispatchStates) => {
  if (dispatchingSendCommand) {
    return t`Sending`;
  }

  if (pendingSendCommand) {
    return t`Wait`;
  }

  if (pendingSuccess) {
    return t`Success`;
  }

  return "";
};

const readyForDispatch = (
  operationalState?: OperationalStates,
  mode?: AutomationMode
) => {
  return (
    (mode === AutomationMode.MODE_AUTONOMOUS || AutomationMode.MODE_UNMANNED) &&
    (operationalState === OperationalStates.OPERATIONAL_STATE_IDLE ||
      operationalState === OperationalStates.OPERATIONAL_STATE_IDLE_RESTRICTED)
  );
};

type DispatchProps = {
  states: DispatchStates;
  actions: DispatchActions;
  actionLists: Array<ActionLists>;
  mode?: AutomationMode;
  operationalState?: OperationalStates;
};

const Dispatch: React.FC<DispatchProps> = React.memo(
  ({ states, actions, actionLists, mode, operationalState }) => {
    const [actionListId, setActionListsId] = useState("");
    const [selectedActionList, setSelectedActionList] = useState<
      Array<MissionActionInput>
    >([]);
    const [dispatchChecked, setDispatchChecked] = useState(false);
    const {
      fetching,
      fetchingFailed,
      dispatchingFailed,
      pendingFailed,
      pendingRejected,
      pendingSuccess,
    } = states;

    const dispatchCallback = useCallback(() => {
      setDispatchChecked(true);
      actions.selectDispatchVehicle(actionListId, selectedActionList);
    }, [actionListId, actions, selectedActionList]);

    const getErrorMessage = useCallback(() => {
      if (dispatchingFailed) {
        return t`Could not send dispatch command`;
      }
      if (pendingFailed) {
        return t`Could not verify dispatch command was accepted`;
      }
      if (pendingRejected) {
        return t`Dispatch command was rejected`;
      }

      return undefined;
    }, [dispatchingFailed, pendingFailed, pendingRejected]);

    useEffect(() => {
      if (getErrorMessage()) {
        setDispatchChecked(false);
      }
    }, [getErrorMessage]);

    useEffect(() => {
      actions.selectActionLists();
    }, [actions]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setActionListsId((event.target as HTMLInputElement).value);

      actionLists.map(
        (currentActionList: {
          id: string;
          actions: React.SetStateAction<MissionActionInput[]>;
        }) => {
          if (
            currentActionList.id === (event.target as HTMLInputElement).value
          ) {
            setSelectedActionList(currentActionList.actions);
          }

          return true;
        }
      );
    };

    const handleDispatch = dispatchCallback;

    const renderError = () => {
      const errorMessage = getErrorMessage();

      return errorMessage ? (
        <Lab.Alert severity="error">{errorMessage}</Lab.Alert>
      ) : null;
    };

    if (fetchingFailed) {
      return (
        <>
          <VehicleActionViewport>
            <Lab.Alert severity="error">{t`Fetching dispatch locations failed`}</Lab.Alert>
          </VehicleActionViewport>
          <VehicleActionButton color="secondary" onClick={actions.handleRetry}>
            {t`Retry`}
          </VehicleActionButton>
          <VehicleActionButton color="secondary" onClick={actions.handleAbort}>
            {t`Abort`}
          </VehicleActionButton>
        </>
      );
    }

    if (!readyForDispatch(operationalState, mode)) {
      return (
        <VehicleActionViewport>
          <Lab.Alert
            severity="warning"
            data-testid="dispatch-warning"
          >{t`Dispatch not available until vehicle is idle`}</Lab.Alert>
        </VehicleActionViewport>
      );
    }

    return (
      <Box
        flex="auto"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        component="form"
      >
        <VehicleActionViewport>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t`Select Destination`}</FormLabel>
            {fetching ? (
              <Box mt={1}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <RadioGroup
                aria-label="action"
                name="action"
                value={actionListId}
                onChange={handleChange}
              >
                {actionLists.map((actionList) => (
                  <FormControlLabel
                    key={actionList.id}
                    value={actionList.id}
                    control={<Radio />}
                    label={actionList.displayName}
                  />
                ))}
              </RadioGroup>
            )}
          </FormControl>
        </VehicleActionViewport>
        {renderError()}
        {actionListId.length ? (
          <SwitchSlider
            leftLabel={getDispatchStatus(states)}
            rightLabel={t`Send to destination`}
            onChecked={handleDispatch}
            checked={dispatchChecked}
          />
        ) : null}
        <VehicleActionButton
          onClick={
            pendingSuccess ? actions.handleDoneDispatch : actions.handleAbort
          }
        >
          {t`${pendingSuccess ? "Done" : "Cancel"}`}
        </VehicleActionButton>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    isEqual(
      omit(prevProps.states, "fetching"),
      omit(nextProps.states, "fetching")
    ) &&
    isEqual(prevProps.actionLists, nextProps.actionLists) &&
    isEqual(prevProps.mode, nextProps.mode) &&
    isEqual(prevProps.operationalState, nextProps.operationalState)
);

export default Dispatch;
