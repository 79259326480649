import React from "react";
import { useService } from "@xstate/react";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";

import LockIcon from "./LockIcon.component";

type LockIconContainerProps = {
  vehicleRef: VehicleService;
};

const LockIconContainer = ({ vehicleRef }: LockIconContainerProps) => {
  const [state] = useService(vehicleRef);
  const { status } = state.context.data;
  return <LockIcon status={status} />;
};

export default LockIconContainer;
