import React from "react";
import { useService } from "@xstate/react";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";

import ConnectedVehicleIcon from "./ConnectedVehicleIcon.component";

type ConnectedVehicleIconContainerProps = {
  vehicleRef: VehicleService;
  size: number;
};

const ConnectedVehicleIconContainer = ({
  vehicleRef,
  size,
}: ConnectedVehicleIconContainerProps) => {
  const [state] = useService(vehicleRef);
  const { status } = state.context.data;
  return <ConnectedVehicleIcon status={status} size={size} />;
};

export default ConnectedVehicleIconContainer;
