import { BehaviorSubject as StateHolder } from "rxjs";

// Observable for storing currently selected site/area without having to check the state maching
export const selectedSiteId = new StateHolder<string | null>(null);

export const selectedVehicleId = new StateHolder<string | null>(null);

export const selectedQueueId = new StateHolder<string | null>(null);

export const selectedPaddockId = new StateHolder<string | null>(null);

export const currentAccessToken = new StateHolder<string | null>(null);

export const lastConnected = new StateHolder<string | null | undefined>(
  undefined
);
