import React, { useState } from "react";
import { makeStyles, SvgIcon, CircularProgress } from "@material-ui/core";
import { IconButton, Menu, Box, Grid } from "@src/components";
import { sendExternalHmiSignal } from "@ats/graphql";
import Events from "../../helpers/events";
import { ICommonIconProps } from "../CommonIconProps";

interface IProps extends ICommonIconProps {
  externalEquipmentReference: string;
}

export const useStyles = makeStyles({
  button: {
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      border: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      border: "none",
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
  },
  label: {
    paddingLeft: "5px",
  },
});

const VehicleIdentification = ({ externalEquipmentReference }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [identifyingVehicle, setidentifyingVehicle] = useState<boolean>(false);
  const { button, label, container } = useStyles();
  const isOpen = Boolean(anchorEl);

  const close = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const sendIdentifyVehicle = () => {
    setidentifyingVehicle(true);
    sendExternalHmiSignal(externalEquipmentReference)
      .then(() => {
        setidentifyingVehicle(false);
        const detail = {
          message: `Vehicle Identified`,
          toastClass: `success`,
        };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        close();
      })
      .catch(() => {
        const detail = {
          message: `Failed to Identify vehicle`,
          toastClass: `warning`,
        };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        setidentifyingVehicle(false);
        close();
      });
  };

  const handleClick = () => {
    sendIdentifyVehicle();
  };

  return (
    <>
      <Grid item>
        <Box>
          <IconButton onClick={handleMenu} edge="end" className={button}>
            <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
              <path
                d="M10.5 5.98242C10.5 6.81085 11.1716 7.48242 12 7.48242C12.8284 7.48242 13.5 6.81085 13.5 5.98242C13.5 5.15399 12.8284 4.48242 12 4.48242C11.1716 4.48242 10.5 5.15399 10.5 5.98242Z"
                fill="rgb(151, 153, 155)"
              />
              <path
                d="M10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12Z"
                fill="rgb(151, 153, 155)"
              />
              <path
                d="M10.5 18.022C10.5 18.8504 11.1716 19.522 12 19.522C12.8284 19.522 13.5 18.8504 13.5 18.022C13.5 17.1935 12.8284 16.522 12 16.522C11.1716 16.522 10.5 17.1935 10.5 18.022Z"
                fill="rgb(151, 153, 155)"
              />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box className="menu" id="vehicle-identification">
          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={isOpen}
            onClose={close}
            PaperProps={{
              style: {
                padding: "4px 12px",
                cursor: "pointer",
                transform: "translateX(-15px) translateY(25px)",
              },
            }}
            onClick={handleClick}
          >
            <div className={container}>
              {identifyingVehicle ? (
                <CircularProgress size={16} color="primary" thickness={5} />
              ) : null}

              <span className={label}>
                {identifyingVehicle ? " Identifying" : " Identify vehicle"}
              </span>
            </div>
          </Menu>
        </Box>
      </Grid>
    </>
  );
};

export default VehicleIdentification;
