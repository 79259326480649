// Maybe should a machine
import { StateNodeConfig, assign } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { getActionListsPromise } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface ActionListsStateSchema {
  states: {
    fetching: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    success: {};
    failed: {};
    done: {};
  };
}

export type ActionListsEvent = {
  type: "onDone";
  data: [];
};

export const getActionLists = () => async (context: VehicleContext) => {
  return getActionListsPromise({ areaId: context.data.areaId || "" });
};

const actionListsStates: StateNodeConfig<
  VehicleContext,
  ActionListsStateSchema,
  VehicleEvent
> = {
  id: "actionLists",
  initial: "fetching",
  states: {
    fetching: {
      invoke: {
        src: "getActionLists",
        onDone: {
          target: "success",
          actions: assign<VehicleContext, ActionListsEvent>({
            actionLists: (_context, event) => event.data,
          }) as any, // https://github.com/davidkpiano/xstate/issues/1198,
        },
        onError: {
          target: "failed",
          actions: (_context, event) => {
            // eslint-disable-next-line no-console
            console.error("Error fetching action lists", event);
          },
        },
      },
    },
    success: {
      always: "done",
    },
    failed: {
      on: {
        RETRY_VEHICLE_ACTION: "fetching",
      },
    },
    done: {
      type: "final",
    },
  },
};

export default actionListsStates;
