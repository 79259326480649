import React, { useState, useEffect } from "react";

import {
  VehiclesQuery_allEquipment_status as VehicleStatus,
  VehiclesQuery_allEquipment_status_releaseConditions_staff as Staff,
} from "@src/graphql/schemaTypes";
import { Box, Icons, Typography } from "@src/components";
import { fetchStaffFG } from "../VehicleOnlineGuard.container";

const VehicleReleaseConditions: React.FC<{
  status: VehicleStatus;
}> = ({ status }) => {
  const { releaseConditions, owner } = status;
  let lock = false;
  if (owner) {
    lock = true;
  }

  const colleagues: Staff[] = [];

  const [meData, setMeData] = useState<Staff | null>(null);
  const [colleaguesData, setColleaguesData] = useState<Staff[]>([]);
  const derivedReleaseConditions = JSON.stringify(releaseConditions);

  useEffect(() => {
    setMeData(null);
    setColleaguesData([]);
    releaseConditions.forEach((item) => {
      if (item.owner) {
        const isMe = item.owner === (window as any).external_staff_reference;
        if (isMe) {
          const isMeData = fetchStaffFG(item.owner, isMe).then((data) => {
            return (data as unknown) as Staff;
          });
          // eslint-disable-next-line no-unused-expressions
          isMeData?.then((staff) => setMeData(staff));
        } else {
          const colleagueData = fetchStaffFG(item.owner, isMe).then((data) => {
            return (data as unknown) as Staff;
          });
          colleagueData.then((staff) => colleagues.push(staff));
          setColleaguesData(colleagues);
        }
      }
    });
    // Only re-render if the releaseConditions array change, but use derivedReleaseConditions instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedReleaseConditions]);

  const renderStaff = ({
    firstName,
    lastName,
    isMe,
    externalStaffReference,
  }: Staff) => {
    const initials = firstName
      .charAt(0)
      .concat(lastName.charAt(0))
      .toLocaleUpperCase();

    return (
      <Box
        display="flex"
        alignItems="flex-end"
        bgcolor="background.paper"
        flexDirection={isMe ? undefined : "row-reverse"}
        key={externalStaffReference}
      >
        <Icons.AccountCircle
          fontSize="small"
          color={isMe ? "primary" : undefined}
        />
        <Typography variant="caption">{initials}</Typography>
      </Box>
    );
  };

  return (
    <Box position="relative" width={1} height={1}>
      {meData && (
        <Box position="absolute" top="10%" right="10%">
          {renderStaff(meData)}
        </Box>
      )}
      {lock && (
        <Box position="absolute" right="40%">
          <Icons.Lock fontSize="large" />
        </Box>
      )}

      {colleaguesData && (
        <Box position="absolute" left="10%" bottom="60%">
          {colleaguesData.map((staff) => {
            return renderStaff(staff);
          })}
        </Box>
      )}
    </Box>
  );
};

export default VehicleReleaseConditions;
