import { Mission_actions as MissionActions } from "@ats/graphql";

export const isValidJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
};

export const extractActionLevelTags = (tags: MissionActions["tags"]) =>
  tags && isValidJSON(tags) ? JSON.parse(tags) : {};
