import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useUiService } from "@src/hooks";
import { Box, Typography, Card, Chip, Grid } from "@src/components";
import dayjs from "@src/helpers/time";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";
import { useThrottledService } from "@src/machines/utils/useThrottledService";
import { Header } from "@src/containers";
import { t } from "ttag";
import { Can } from "@src/casl/Can";
import {
  getStaffFg,
  useMissionStates,
  useMissions,
  useDegradations,
} from "@ats/graphql";
import { VehiclesQuery_allEquipment_status_staff as Staff } from "@src/graphql/schemaTypes";
import startLoadingCheck from "@src/app/model/mission/startLoadingCheck";
import ToastBanner from "@src/components/Alert/ToastBanner.component";
import CurrentDispatcher from "./status/CurrentDispatcher.component";
import ToastContainer from "../Vehicles/Toast.container";
import VehicleStatus from "./status/VehicleStatus.component";
import VehicleAction from "./actions/VehicleAction.component";
import InVehicleAndPersonalStandDown, {
  getInVehicleAndPersonalStandDownStates,
  getInVehicleAndPersonalStandDownActions,
} from "./actions/InVehicleAndPersonalStandDown/InVehicleAndPersonalStandDown.component";
import StartLoading, {
  getStartLoadingStates,
  getStartLoadingActions,
} from "./actions/StartLoading/StartLoading.component";
import FinishLoading, {
  getFinishLoadingStates,
  getFinishLoadingActions,
} from "./actions/FinishLoading/FinishLoading.component";
import ReleasePersonalStandDown, {
  getReleasePersonalStandDownStates,
  getReleasePersonalStandDownActions,
} from "./actions/ReleasePersonalStandDown/ReleasePersonalStandDown.component";
import PersonalOwnership, {
  getPersonalOwnershipStates,
  getPersonalOwnershipActions,
} from "./actions/PersonalOwnership/PersonalOwnership.component";
import Dispatch, {
  getDispatchStates,
  getDispatchActions,
} from "./actions/Dispatch/Dispatch.component";
import ReleaseToContinue, {
  getReleaseToContinueStates,
  getReleaseToContinueActions,
} from "./actions/ReleaseToContinue/ReleaseToContinue.component";
import VehicleMode from "./status/VehicleMode.component";
import eStopButtonDegradationStatus from "./status/EstopButtonDegradationStatus";
import LASDButtonDegradationStatus from "./status/LASDButtonDegradationStatus";
import VehicleActionButtonContainer from "./VehicleActionButton.container";
import ReleaseVehicle, {
  getReleaseVehicleActions,
  getReleaseVehicleStates,
} from "./actions/ReleaseVehicle/ReleaseVehicle.component";
import LoadingComponent from "../../components/Loading/Loading.component";

type VehicleOnlineGuardProps = {
  vehicleRef: VehicleService;
  handleBack?: () => void;
};

// Accepted offset delay
const offsetDelay = 60;
const useStyles = makeStyles({
  vehicleStatusContainer: {
    maxWidth: "320px",
    marginTop: "14px",
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "@media (max-width: 600px)": {
      paddingTop: "10px",
    },
    overflow: "hidden",
  },
  bannerContainer: {
    paddingTop: "5px",
    position: "absolute",
  },
});

// istimestampFresh considering offset delay of 60 seconds
export const isFreshTimestamp = (latest: string | null) => {
  if (!latest) {
    return false;
  }

  const allowedDelay = dayjs().utc().subtract(offsetDelay, "second");
  const latestUtc = dayjs(latest).utc();
  return latestUtc.isAfter(allowedDelay);
};

export const formatDate = (lastKnownTimestamp: string | null) => {
  if (!lastKnownTimestamp) {
    return "-";
  }

  return new Date(
    dayjs(lastKnownTimestamp).local().valueOf()
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const fetchStaffFG = (externalStaffReference: string, isMe: boolean) => {
  return new Promise((resolve) => {
    getStaffFg(externalStaffReference).then((result: any) => {
      const data = {
        __typename: "Staff",
        externalStaffReference,
        firstName: result?.givenName || "unknown",
        lastName: result?.familyName || "unknown",
        isMe,
      } as Staff;
      resolve(data);
    });
  });
};

const VehicleOnlineGuard: React.FC<VehicleOnlineGuardProps> = ({
  vehicleRef,
  handleBack,
}) => {
  const [uiState, uiSend] = useUiService();
  const [vehicleState, vehicleSend] = useThrottledService(vehicleRef);
  const { lastKnownTimestamp } = vehicleState.context;
  const { displayName, status } = vehicleState.context.data;
  const [staff, setStaff] = useState<Promise<Staff> | null>(null);
  const owner = status?.owner || null;
  const reason = status?.reason || null;
  const vehicleId = uiState.context?.vehicleId || null;
  const siteId = uiState.context?.siteId || null;
  const [states] = useMissionStates({
    externalEquipmentReference: vehicleId,
    areaId: siteId,
  });
  const [missions] = useMissions({
    externalEquipmentReference: vehicleId,
    areaId: siteId,
  });
  const [degradations] = useDegradations({
    externalEquipmentReference: vehicleId,
    areaId: siteId,
  });
  const startLoadingFlag = startLoadingCheck(missions, states);
  const isMe = owner === (window as any).external_staff_reference;
  const {
    eStopDegradationWithActiveFault,
    eStopDegradationWithHealedFault,
  } = eStopButtonDegradationStatus(degradations);
  const {
    LASDDegradationWithActiveFault,
    LASDDegradationWithHealedFault,
  } = LASDButtonDegradationStatus(degradations);
  const {
    vehicleStatusContainer,
    contentContainer,
    bannerContainer,
  } = useStyles();

  useEffect(() => {
    const staffData =
      (owner &&
        fetchStaffFG(owner, isMe).then((data) => {
          return (data as unknown) as Staff;
        })) ||
      null;
    setStaff(staffData);
  }, [isMe, owner]);

  if (!status || status?.status === null) {
    return (
      <>
        <Header
          uiState={uiState}
          sites={uiState.context.sites}
          uiSend={uiSend}
          text={displayName}
          handleBack={handleBack}
        />
        <Can I="READ" a="Vehicle">
          <div className={bannerContainer}>
            <ToastBanner
              severity="error"
              toastHeaderText={t`No vehicle data`}
              toastBodyText={t`No historical vehicle data has been received from the vehicle (including vehicle position).`}
              testid="vehicle-no-equipment-status"
            />
          </div>
          <LoadingComponent />
        </Can>
      </>
    );
  }

  const {
    mode,
    speed,
    externalEquipmentReference,
    dispatcher,
    timestamp,
  } = status;

  const isEquipmentStatusStale = !isFreshTimestamp(timestamp);

  // If the vechicle is locked by someone other than me we want to prevent dispatch, but we will also display -
  // the info about who locked it in the PersonalOwnership container
  const lockedBySomeoneElse = !!(owner && !isMe);

  const isInAutonomousMode = mode === "MODE_AUTONOMOUS";
  const handleDone = () => uiSend("VIEW_VEHICLE");
  const expectedOffline = reason && reason.toLowerCase() === "expected";
  const showAction = (uiState as any).matches("authenticated.vehicle.idle");

  const OfflineContent = () => (
    <div className={bannerContainer}>
      {expectedOffline ? (
        <ToastBanner
          severity="info"
          toastHeaderText={t`Vehicle is offline`}
          toastBodyText={t`The vehicle is offline due to ignition being turned off.`}
          testid="vehicle-offline-expected"
        />
      ) : (
        <ToastBanner
          severity="error"
          toastHeaderText={t`Vehicle is offline`}
          toastBodyText={t`Vehicle has unexpectedly gone offline.`}
          toastSubBodyText={
            t`Last update from vehicle at: ` + formatDate(lastKnownTimestamp)
          }
          testid="vehicle-offline-unexpected"
        />
      )}
    </div>
  );

  const OnlineContent = () => (
    <>
      {isEquipmentStatusStale && (
        <ToastBanner
          severity="error"
          toastHeaderText={t`No vehicle data`}
          toastBodyText={t`Vehicle appears to be online but no vehicle data is being recieved and updated.`}
          testid="vehicle-online-no-equipment-status"
        />
      )}
      {eStopDegradationWithHealedFault && (
        <ToastBanner
          severity="warning"
          toastHeaderText={t`The Remote stop has been deactivated`}
          testid="e-stop-deactivated-warning-alert"
        />
      )}
      {eStopDegradationWithActiveFault && (
        <ToastBanner
          severity="error"
          toastHeaderText={t`The Remote stop is active`}
          testid="e-stop-activated-error-alert"
        />
      )}
      {LASDDegradationWithHealedFault && (
        <ToastBanner
          severity="warning"
          toastHeaderText={t`The LASD has been deactivated`}
          testid="lasd-deactivated-warning-alert"
        />
      )}
      {LASDDegradationWithActiveFault && (
        <ToastBanner
          severity="error"
          toastHeaderText={t`The LASD button has been activated`}
          testid="lasd-activated-error-alert"
        />
      )}
      <Box
        className={contentContainer}
        bgcolor={isInAutonomousMode ? "success.main" : "grey"}
        style={{
          opacity: isEquipmentStatusStale ? "0.5" : "",
          pointerEvents: isEquipmentStatusStale ? "none" : "auto",
        }}
      >
        <Box
          mt={3}
          ml="auto"
          mr="auto"
          width={1}
          className={vehicleStatusContainer}
        >
          <VehicleStatus status={status} />
        </Box>
        <Box flex="auto" display="flex" flexDirection="column" mt={2}>
          <Box flex="auto" position="relative">
            <Box height={1} width={1} overflow="auto" padding={1}>
              <Card>
                <Box padding={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      {t`Mode`}:
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        <VehicleMode status={status} />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {t`Speed`}:
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        {Math.round(speed * 3.6)}
                        {t` km/h`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {t`Dispatcher`}:
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right" component="span">
                        <CurrentDispatcher dispatcher={dispatcher} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Box>
          <Box bgcolor="background.paper" p={2}>
            {(status.canApproach || status.canLeave) && (
              <Can I="IN_VEHICLE_AND_PERSONAL_STAND_DOWN" a="Vehicle">
                <InVehicleAndPersonalStandDown
                  states={getInVehicleAndPersonalStandDownStates(vehicleState)}
                  actions={getInVehicleAndPersonalStandDownActions(vehicleSend)}
                />
              </Can>
            )}
            {status.personalStandDownByMe && (
              <Can I="RELEASE_PERSONAL_STAND_DOWN" a="Vehicle">
                <ReleasePersonalStandDown
                  states={getReleasePersonalStandDownStates(vehicleState)}
                  actions={getReleasePersonalStandDownActions(vehicleSend)}
                />{" "}
              </Can>
            )}
            {(status.canApproach || status.canLeave) && startLoadingFlag && (
              <Can I="PERSONAL_STAND_DOWN" a="Vehicle">
                <StartLoading
                  states={getStartLoadingStates(vehicleState)}
                  actions={getStartLoadingActions(vehicleSend)}
                />
              </Can>
            )}
            {status.personalStandDownByMe && (
              <Can I="PERSONAL_STAND_DOWN" a="Vehicle">
                <FinishLoading
                  states={getFinishLoadingStates(vehicleState)}
                  actions={getFinishLoadingActions(vehicleSend)}
                  externalEquipmentReference={externalEquipmentReference}
                  startLoadingFlag
                />
              </Can>
            )}
            <Can I="DISPATCH_VEHICLE" a="Vehicle">
              <VehicleAction
                done={handleDone}
                title={t`Dispatch`}
                open={(uiState as any).matches(
                  "authenticated.vehicle.dispatch"
                )}
              >
                <Dispatch
                  states={getDispatchStates(vehicleState)}
                  actions={getDispatchActions(vehicleSend, handleDone)}
                  actionLists={vehicleState.context.actionLists}
                  operationalState={status?.operationalState}
                  mode={status?.mode}
                />
              </VehicleAction>
            </Can>
            <Can I="RELEASE_TO_CONTINUE" a="Vehicle">
              <VehicleAction
                done={handleDone}
                title={t`Release and automatically dispatch`}
                open={(uiState as any).matches(
                  "authenticated.vehicle.releaseToContinue"
                )}
              >
                <ReleaseToContinue
                  states={getReleaseToContinueStates(vehicleState)}
                  actions={getReleaseToContinueActions(vehicleSend, handleDone)}
                />
              </VehicleAction>
            </Can>
            {showAction && (
              <>
                <VehicleActionButtonContainer
                  action="DISPATCH_VEHICLE"
                  disabled={
                    lockedBySomeoneElse ||
                    dispatcher?.systemName === "ICE_UI" ||
                    dispatcher?.systemName === "WENCO"
                  }
                  onClick={() => uiSend("DISPATCH_VEHICLE")}
                  testId="dispatch-button"
                  labelTitle={t`Mission`}
                  buttonText={t`Dispatch`}
                />
                <ReleaseVehicle
                  states={getReleaseVehicleStates(vehicleState)}
                  actions={getReleaseVehicleActions(vehicleSend, handleDone)}
                  eStopDegradationWithHealedFault={
                    eStopDegradationWithHealedFault
                  }
                  LASDDegradationWithHealedFault={
                    LASDDegradationWithHealedFault
                  }
                />
                <PersonalOwnership
                  states={getPersonalOwnershipStates(vehicleState)}
                  actions={getPersonalOwnershipActions(vehicleSend, handleDone)}
                  lockedBy={owner ? staff : null}
                  key={owner || "noOwner"}
                />
              </>
            )}
            <ToastContainer />
          </Box>
        </Box>
      </Box>
    </>
  );

  const content = vehicleState.matches("onlineEvaluation.isOffline")
    ? OfflineContent
    : OnlineContent;

  return (
    <>
      <Header
        uiState={uiState}
        uiSend={uiSend}
        text={
          <>
            {displayName}
            {isInAutonomousMode ? (
              <Box ml={1} display="inline-block">
                <Chip color="primary" size="small" label={t` Autonomous`} />
              </Box>
            ) : null}
          </>
        }
        handleBack={handleBack}
      />
      <Can I="READ" a="Vehicle">
        {content}
      </Can>
    </>
  );
};

export default memo(VehicleOnlineGuard);
