import { StateNodeConfig, send } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";

export interface OnlineEvaluationSchema {
  states: {
    isOnline: {};
    isOffline: {};
  };
}

export const vehicleOnlineGuard = (context: VehicleContext) => {
  return context.data.status && context.data.status.status
    ? context.data.status.status.toLowerCase() === "online"
    : false;
};

export const vehicleOfflineGuard = (context: VehicleContext) => {
  return context.data.status && context.data.status.status
    ? context.data.status.status.toLowerCase() === "offline"
    : false;
};

const onlineEvaluationStates: StateNodeConfig<
  VehicleContext,
  OnlineEvaluationSchema,
  VehicleEvent
> = {
  id: "vehicleEvaluation",
  initial: "isOnline",
  states: {
    isOnline: {
      after: {
        1000: [
          { target: "isOffline", cond: "vehicleOfflineGuard" },
          { target: "isOnline" },
        ],
      },
    },
    isOffline: {
      on: {
        VEHICLE_STATUS: { target: "isOnline", cond: "vehicleOnlineGuard" },
      },
      entry: send("VEHICLE_OFFLINE"),
      exit: send("VEHICLE_ONLINE"),
    },
  },
};

export default onlineEvaluationStates;
