import React, { useState, ChangeEvent, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import styled from "styled-components";
import { useLongPress } from "use-long-press";
import { Box, Typography } from "..";

type ValueType = number | number[];
type HandleChangeType = (event: ChangeEvent<{}>, value: ValueType) => void;

type SwitchSliderProps = {
  onChecked: () => void;
  checked?: boolean;
  direction?: "ltr" | "rtl";
  leftLabel?: string;
  rightLabel?: string;
};

const Container = styled("div")`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[200]};

  border-radius: 5px;

  text-align: center;
  text-transform: uppercase;
  user-select: none;
  z-index: 0;
`;

const ThumbComponent = styled("span")`
  &,
  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border: 1px solid;
    height: 100%;
    width: 100%;
    margin: 0 -50%;
    border-radius: 5px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 25%;
      bottom: 25%;
      left: 50%;
      right: 0;
      transform: translateX(-100%);
      margin-left: 1px;

      border-radius: 0;
      border: 1px solid;
      border-width: 0 1px;
      width: 8px;
    }

    &:after {
      transform: translateX(100%);
      margin-left: -1px;
    }
  }
`;

const StyledSlider = styled(Slider)`
  position: absolute;
  height: 100%;
  width: auto;
  left: 25%;
  right: 25%;
  z-index: 1;

  padding: 0 25%;

  .MuiSlider-rail {
    display: none;
  }
`;

const Label = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const SwitchSlider = ({
  onChecked,
  checked,
  direction = "ltr",
  leftLabel,
  rightLabel,
}: SwitchSliderProps) => {
  const [controlledValue, setControlledValue] = useState<ValueType>(
    direction === "ltr" ? 0 : 1
  );
  const [enabled, setEnabled] = useState(false);

  // Call onChecked callback
  useEffect(() => {
    if (
      (direction === "ltr" && controlledValue === 1) ||
      (direction === "rtl" && controlledValue === 0)
    ) {
      onChecked();
    }
  }, [direction, controlledValue, onChecked]);

  // Set controlledValue and enabled if checked is set to true
  useEffect(() => {
    const set = () => {
      setControlledValue(direction === "ltr" ? 1 : 0);
      setEnabled(true);
    };

    if (checked) {
      set();
    }
  }, [checked, direction]);

  // Reset controlledValue and enabled if checked is set to false or undefined
  useEffect(() => {
    const reset = () => {
      setControlledValue(direction === "ltr" ? 0 : 1);
      setEnabled(false);
    };

    if (!checked) {
      reset();
    }
  }, [direction, checked]);

  const bind = useLongPress(
    () => {
      if (!enabled) {
        setEnabled(true);
      }
    },
    { threshold: 750 }
  );

  const handleChange: HandleChangeType = (_event, value) => {
    if (checked) {
      return;
    }

    setControlledValue(value);
  };
  const handleChangeCommited: HandleChangeType = (_event, value) => {
    if (checked) {
      return;
    }

    setControlledValue(
      (direction === "ltr" && value === 1) || (direction === "rtl" && value > 0)
        ? 1
        : 0
    );
  };

  const renderLabel = (
    label: SwitchSliderProps["leftLabel"] | SwitchSliderProps["leftLabel"]
  ) => (
    <Label>
      <Typography
        color={enabled ? "textSecondary" : "textPrimary"}
        component="span"
        onDoubleClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          if (e.ctrlKey) {
            if (direction === "ltr") {
              setControlledValue(1);
            } else {
              setControlledValue(0);
            }
          }
        }}
      >
        {label}
      </Typography>
    </Label>
  );

  const renderEnabler = () =>
    enabled ? null : (
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bind}
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
      />
    );

  return (
    <Container>
      <StyledSlider
        value={controlledValue}
        min={0}
        max={1}
        step={0.001}
        track={false}
        ThumbComponent={ThumbComponent}
        disabled={!enabled}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommited}
      />
      <Box display="flex" alignItems="center">
        <Box flexGrow="1" flexBasis="0" position="relative">
          {renderLabel(leftLabel)}
          {direction === "ltr" && renderEnabler()}
        </Box>
        <Box flexGrow="1" flexBasis="0" position="relative">
          {renderLabel(rightLabel)}
          {direction === "rtl" && renderEnabler()}
        </Box>
      </Box>
    </Container>
  );
};

export default SwitchSlider;
