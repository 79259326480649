import React from "react";
import { VehicleService } from "@src/machines/vehicle/vehicle.machine";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import { useUser } from "@src/hooks";
import { Button, Lab } from "@src/components";
import { Header } from "@src/containers";
import { t } from "ttag";
import VehicleOnlineGuard from "./VehicleOnlineGuard.container";

type VehicleProps = {
  vehicleRef?: VehicleService;
  uiSend: (event: UiEvent) => void;
  uiState: UiState;
};

const Vehicle = React.memo(
  ({ vehicleRef, uiSend, uiState }: VehicleProps) => {
    const zoneType = uiState.context?.zoneType || "";
    const zoneName = uiState.context?.zoneName || "";
    const paddockId = uiState.context?.paddockId || "";
    const queueId = uiState.context?.queueId || "";
    const { role } = useUser();
    const handleBack = () =>
      role === "loadingOperator"
        ? uiSend({
            type: "SELECT_ZONE",
            zoneType,
            zoneName,
            paddockId,
            queueId,
          })
        : uiSend({ type: "VIEW_VEHICLES" });

    if (!vehicleRef) {
      const action = (
        <Button
          color="primary"
          onClick={handleBack}
          data-testid="vehicle-back-button"
        >
          {t`Select another vehicle`}
        </Button>
      );

      return (
        <>
          <Header
            sites={uiState.context.sites}
            text=""
            handleBack={handleBack}
          />
          <Lab.Alert severity="error" action={action}>
            {t`Vehicle not found!`}
          </Lab.Alert>
        </>
      );
    }

    return (
      <VehicleOnlineGuard vehicleRef={vehicleRef} handleBack={handleBack} />
    );
  },
  (prevProps, nextProps) => prevProps.vehicleRef === nextProps.vehicleRef
);

export default Vehicle;
