import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendEnterSafeState } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface InVehicleAndPersonalStandDownStateSchema {
  states: {
    sendCommand: {
      states: {
        onDone: {};
        onError: {};
      };
    };
    pending: {};
    success: {};
    failed: {};
  };
}

export const sendInVehicleAndPersonalStandDown = () => async (
  context: VehicleContext
) => {
  return sendEnterSafeState(context.externalEquipmentReference);
};

const inVehicleAndPersonalStandDownStates: StateNodeConfig<
  VehicleContext,
  InVehicleAndPersonalStandDownStateSchema,
  VehicleEvent
> = {
  initial: "sendCommand",
  states: {
    sendCommand: {
      invoke: {
        src: "sendInVehicleAndPersonalStandDown",
        onDone: {
          target: "pending",
        },
        onError: {
          target: "failed",
          actions: (_context, event) => {
            // eslint-disable-next-line no-console
            console.error("Error sending stand down command", event);
          },
        },
      },
    },
    pending: {
      after: {
        30000: "failed",
      },
      always: {
        target: "success",
        cond: "safeToApproachGuard",
      },
    },
    success: {},
    failed: {},
  },
};

export default inVehicleAndPersonalStandDownStates;
