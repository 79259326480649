import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendReleaseControlLock } from "@ats/graphql";
import Events from "@src/helpers/events";

export interface ReleasePersonalOwnershipStateSchema {
  states: {
    releasing: {
      states: {
        sendCommand: {
          states: {
            onDone: {};
            onError: {};
          };
        };
      };
    };
    done: {};
  };
}

export const sendReleasePersonalOwnership = () => async (
  context: VehicleContext
) => {
  return sendReleaseControlLock(context.externalEquipmentReference)
    .then(() => {
      const detail = {
        message: `Vehicle unlocked`,
        toastClass: `success`,
      };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    })
    .catch(() => {
      const detail = {
        message: `Failed to unlock vehicle`,
        toastClass: `warning`,
      };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });
};

const ReleasePersonalOwnershipStates: StateNodeConfig<
  VehicleContext,
  ReleasePersonalOwnershipStateSchema,
  VehicleEvent
> = {
  id: "releasePersonalOwnership",
  initial: "releasing",
  states: {
    releasing: {
      initial: "sendCommand",
      states: {
        sendCommand: {
          invoke: {
            src: "sendReleasePersonalOwnership",
            onDone: {
              target: "#releasePersonalOwnership.done",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error("Personal Ownership release was accepted", event);
              },
            },
            onError: {
              target: "#releasePersonalOwnership.done",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error(
                  "Error releasing personal ownership commands",
                  event
                );
              },
            },
          },
        },
      },
    },
    done: {
      type: "final",
    },
  },
};

export default ReleasePersonalOwnershipStates;
