import { IGraphqlGlobals } from "@ats/graphql";
import {
  selectedSiteId,
  selectedVehicleId,
  selectedQueueId,
  selectedPaddockId,
  lastConnected,
} from "../components/Model/observables";

//
// Expose observables in the window object to ease debugging and testing
//
interface IExposedStates {
  selectedAreaId: string | null | undefined;
  selectedVehicleId: string | null | undefined;
  selectedQueueId: string | null | undefined;
  selectedPaddockId: string | null | undefined;
  lastConnected: string | null | undefined;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    ui: {
      states: IExposedStates;
    };
    graphql: IGraphqlGlobals;
  }
}

window.ui = window.ui || {
  states: {
    selectedAreaId: undefined,
    selectedVehicleId: undefined,
    selectedQueueId: undefined,
    selectedPaddockId: undefined,
    lastConnected: undefined,
  },
};

selectedSiteId.subscribe((id) => {
  window.ui.states.selectedAreaId = id;
});

selectedVehicleId.subscribe((id) => {
  window.ui.states.selectedVehicleId = id;
});

selectedQueueId.subscribe((id) => {
  window.ui.states.selectedQueueId = id;
});

selectedPaddockId.subscribe((id) => {
  window.ui.states.selectedPaddockId = id;
});

lastConnected.subscribe((timestamp) => {
  window.ui.states.lastConnected = timestamp;
});
