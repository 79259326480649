import React, { useState, useEffect, useCallback } from "react";

import { Lab, SwitchSlider, Box } from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { isEqual } from "lodash";
import { t } from "ttag";

type StartLoadingStates = {
  sendCommand: boolean;
  pending: boolean;
  success: boolean;
  failed: boolean;
};

type StartLoadingActions = {
  personalStandDown: () => void;
};

export const getStartLoadingStates = (
  state: VehicleState
): StartLoadingStates => {
  return {
    sendCommand: state.matches(
      "connection.online.command.personalStandDown.sendCommand"
    ),
    pending: state.matches(
      "connection.online.command.personalStandDown.pending"
    ),
    success: state.matches(
      "connection.online.command.personalStandDown.success"
    ),
    failed: state.matches("connection.online.command.personalStandDown.failed"),
  };
};

export const getStartLoadingActions = (
  send: (event: VehicleEvent) => void
): StartLoadingActions => {
  return {
    personalStandDown: () => send({ type: "COMMAND_PERSONAL_STAND_DOWN" }),
  };
};

const getStartLoadingStatus = ({
  sendCommand,
  pending,
  success,
}: StartLoadingStates) => {
  if (sendCommand) {
    return t`Sending`;
  }

  if (pending) {
    return t`Wait`;
  }

  if (success) {
    return t`Success`;
  }

  return "";
};

type StartLoadingProps = {
  states: StartLoadingStates;
  actions: StartLoadingActions;
};

const StartLoading: React.FC<StartLoadingProps> = React.memo(
  ({ states, actions }) => {
    const [checked, setChecked] = useState(false);
    const { failed } = states;
    const callback = useCallback(() => {
      setChecked(true);
      actions.personalStandDown();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (failed) {
        setChecked(false);
      }
    }, [actions, failed]);

    const handleChecked = callback;

    return (
      <Box position="relative">
        {failed && (
          <Box bottom="100%" width={1}>
            <Lab.Alert severity="error">
              <Lab.AlertTitle>{t`Unable to Start Loading`}</Lab.AlertTitle>
            </Lab.Alert>
          </Box>
        )}
        <SwitchSlider
          leftLabel={getStartLoadingStatus(states)}
          rightLabel={t`Start Loading`}
          checked={checked}
          onChecked={handleChecked}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.states, nextProps.states)
);

export default StartLoading;
