import { Degradations } from "@ats/graphql";

interface ILASDButtonDegradationStatus {
  LASDDegradationWithActiveFault: boolean;
  LASDDegradationWithHealedFault: boolean;
}
const LASDButtonDegradationStatus = (
  degradations: ReadonlyArray<Degradations> | null
): ILASDButtonDegradationStatus => {
  const isDegradationActive =
    degradations !== null &&
    degradations.length > 0 &&
    degradations[0].degradations.includes(
      "BehvV2xHandlerLasdNotReceivedExpectedBeacon"
    );
  const isFaultActive =
    degradations !== null &&
    degradations.length > 0 &&
    degradations[0].faults.includes(
      "BehvV2xHandlerLasdNotReceivedExpectedBeacon"
    );

  return {
    LASDDegradationWithActiveFault: isDegradationActive && isFaultActive,
    LASDDegradationWithHealedFault: isDegradationActive && !isFaultActive,
  };
};
export default LASDButtonDegradationStatus;
