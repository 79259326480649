import React, { createContext, useState } from "react";

import { UserRole } from "@src/casl/ability";
import { usePersist } from "../hooks";

const defaultRole: UserRole = "loadingOperator";

export const UserContext = createContext({
  role: defaultRole as UserRole,
  // eslint-disable-next-line no-unused-vars
  setRole: (role: UserRole) => {},
});

const UserProvider: React.FC = ({ children }) => {
  const [persistedRole, setPersistedRole] = usePersist("userRole");
  const [user, setUser] = useState({
    role: persistedRole ?? (defaultRole as UserRole),
  });
  const setRole = (role: UserRole) => {
    setPersistedRole(role);
    setUser({ ...user, role });
  };

  return (
    <UserContext.Provider value={{ ...user, setRole }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
