import React, { useEffect } from "react";
import { t } from "ttag";
import { useUiService } from "@src/hooks";
import { Header } from "@src/containers";
import { getAreasPromise } from "@ats/graphql";
import { Backdrop, CircularProgress } from "@src/components";
import Sites from "./Sites.container";

const SitesData = () => {
  const [uiState, uiSend] = useUiService();
  const { sites } = uiState.context;
  const noSiteData = sites && sites.length && sites.length < 1;

  useEffect(() => {
    getAreasPromise().then((response) => {
      uiSend({ type: "FETCH_SITES", sites: response });
    });
  }, [uiSend]);

  return (
    <>
      <Header
        uiState={uiState}
        uiSend={uiSend}
        text={t`DICE`}
        sites={sites}
        siteMenu
      />
      {!noSiteData && <Sites uiState={uiState} uiSend={uiSend} areas={sites} />}

      {noSiteData && (
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
};

export default SitesData;
