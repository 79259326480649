import { StateNodeConfig } from "xstate";
import { sendControlLock } from "@ats/graphql";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import Events from "@src/helpers/events";

export interface PersonalOwnershipStateSchema {
  states: {
    locking: {
      states: {
        sendCommand: {
          states: {
            onDone: {};
            onError: {};
          };
        };
      };
    };
    done: {};
  };
}

export const sendPersonalOwnership = () => async (context: VehicleContext) => {
  return sendControlLock(context.externalEquipmentReference)
    .then(() => {
      const detail = {
        message: `Vehicle locked`,
        toastClass: `success`,
      };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    })
    .catch(() => {
      const detail = {
        message: `Failed to lock vehicle`,
        toastClass: `warning`,
      };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });
};

const PersonalOwnershipStates: StateNodeConfig<
  VehicleContext,
  PersonalOwnershipStateSchema,
  VehicleEvent
> = {
  id: "personalOwnership",
  initial: "locking",
  states: {
    locking: {
      initial: "sendCommand",
      states: {
        sendCommand: {
          invoke: {
            src: "sendPersonalOwnership",
            onDone: {
              target: "#personalOwnership.done",
            },
            onError: {
              target: "#personalOwnership.done",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error("Error sending lock commands", event);
              },
            },
          },
        },
      },
    },
    done: {
      type: "final",
    },
  },
};

export default PersonalOwnershipStates;
