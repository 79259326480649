import React from "react";
import { makeStyles } from "@material-ui/core";
import { Area } from "@ats/graphql";
import { scaniaBlack } from "@src/constants/colors";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import { ReactComponent as SiteIcon } from "@src/assets/icons/site-logo.svg";
import { ReactComponent as ForwardArrow } from "@src/assets/icons/forward-arrow.svg";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@src/components";

type SiteProps = {
  areas?: ReadonlyArray<Area> | null;
  uiState: UiState;
  uiSend: (event: UiEvent) => void;
};

const useStyles = makeStyles(
  {
    root: {
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      height: "calc(100vh)",
      minWidth: "360px",
      margin: "auto",
    },
    siteButton: {
      borderRadius: "0px",
      height: "72px",
      width: "inherit",
      justifyContent: "flex-start",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      boxShadow:
        "0px 3px 3px rgba(0, 0, 0, 0.15), 0px -1px 1px rgba(0, 0, 0, 0.1)",
    },
    header: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      fontWeight: 700,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
    },
  },
  { index: 1 }
);

const Sites = ({ areas, uiSend }: SiteProps) => {
  const { root, header, siteButton } = useStyles();

  return (
    <>
      <List className={root}>
        <div className={header}>Select a site to continue</div>
        {areas &&
          areas.map(({ displayName, id }) => (
            <ListItem
              button
              key={id}
              data-testid={`site-selection-${displayName}`}
              className={siteButton}
              onClick={() =>
                uiSend({ type: "SELECT_SITE", id, siteName: displayName })
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <SiteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText disableTypography>{displayName}</ListItemText>
              <ListItemSecondaryAction>
                <ForwardArrow />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default Sites;
