import React from "react";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import { CircularProgress } from "@src/components";

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  loading,
  disabled,
  children,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiButton {...rest} disabled={disabled || loading}>
    {loading ? <CircularProgress size={24} /> : children}
  </MuiButton>
);

export default Button;
