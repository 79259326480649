import React from "react";
import { VehiclesQuery_allEquipment_status as Status } from "@src/graphql/schemaTypes";

import { Box } from "@src/components";
import { isEqual } from "lodash";

import VehicleSafetyLevel from "./VehicleSafetyLevel.component";
import VehicleActivity from "./VehicleActivity.component";
import VehicleReleaseConditions from "./VehicleReleaseConditions.component";

type VehicleStatusProps = {
  status: Status;
};

const VehicleStatus = React.memo(
  ({ status }: VehicleStatusProps) => {
    return (
      <Box
        bgcolor="background.paper"
        borderRadius="50%"
        pb="100%"
        position="relative"
        mb={1}
      >
        <Box position="absolute" top="25%" bottom="0" left="0" right="0">
          <VehicleSafetyLevel status={status} />
        </Box>
        <Box position="absolute" top="25%" bottom="0" left="0" right="0">
          <VehicleReleaseConditions status={status} />
        </Box>
        <Box
          position="absolute"
          bottom="-1em"
          left="0"
          right="0"
          display="flex"
          justifyContent="center"
        >
          <VehicleActivity status={status} />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.status, nextProps.status)
);

export default VehicleStatus;
