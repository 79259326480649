import React from "react";

import {
  SafeState,
  VehiclesQuery_allEquipment_status as VehicleStatus,
} from "@src/graphql/schemaTypes";

import { ReactComponent as NoStandDownIcon } from "@src/assets/icons/vehicle/no-stand-down.svg";
import { ReactComponent as InVehicleStandDownIcon } from "@src/assets/icons/vehicle/in-vehicle-stand-down.svg";
import { ReactComponent as PersonalStandDown } from "@src/assets/icons/vehicle/personal-stand-down.svg";

const SafetyLevel: React.FC<{ status: VehicleStatus }> = ({ status }) => {
  const { safeState } = status;

  switch (safeState) {
    case SafeState.CAUTION:
      return <PersonalStandDown />;
    case SafeState.SAFE:
      return <InVehicleStandDownIcon />;
    case SafeState.NOT_SAFE:
    default:
      return <NoStandDownIcon />;
  }
};

export default SafetyLevel;
