import React from "react";

import {
  AutomationMode,
  VehiclesQuery_allEquipment_status as VehicleStatus,
} from "@src/graphql/schemaTypes";

import { t } from "ttag";

const VehicleMode: React.FC<{ status: VehicleStatus }> = ({ status }) => {
  const getModeString = (mode?: AutomationMode): string => {
    switch (mode) {
      case AutomationMode.MODE_MANUAL:
        return t`Manual`;
      case AutomationMode.MODE_ASSISTED:
        return t`Assisted`;
      case AutomationMode.MODE_AUTONOMOUS:
        return t`Autonomous`;
      case AutomationMode.MODE_INVALID:
        return t`Invalid`;
      case AutomationMode.MODE_SHADOW:
        return t`Shadow`;
      case AutomationMode.MODE_UNMANNED:
        return t`Unmanned`;
      default:
        return t`Unknown`;
    }
  };

  return <>{getModeString(status ? status.mode : undefined)}</>;
};

export default VehicleMode;
