import { useContext } from "react";
import { useThrottledService } from "@src/machines/utils/useThrottledService";
import { VehiclesContext } from "@src/providers/Vehicles.provider";

export const useVehicles = () => {
  const context = useContext(VehiclesContext);
  return useThrottledService(context.vehicleService);
};

export default useVehicles;
