import React, { useState, useEffect, createContext, useCallback } from "react";
import { isEmpty } from "lodash";
import { useMachine } from "@xstate/react";
import UiMachine, { UiService, UiState } from "@src/machines/ui/ui.machine";
import { config } from "@src/config";
import {
  selectedSiteId,
  selectedVehicleId,
  selectedQueueId,
  selectedPaddockId,
} from "@src/components/Model/observables";
import { usePersist } from "@src/hooks";

export interface Props {
  service: UiService;
}

const UiStateProvider: React.FC<Props> = ({ service, children }) => (
  <UiContext.Provider
    value={{
      uiService: service,
    }}
  >
    {children}
  </UiContext.Provider>
);

export const UiContext = createContext<{
  uiService: UiService;
}>(null as any);

const UiMachineProvider: React.FC = ({ children }) => {
  const [persistedState, setPersistedState] = usePersist("uiState");
  const [uiState, , service] = useMachine(UiMachine, {
    devTools: config.enableDevTools,
    state: persistedState,
  });

  const [mounted, setMounted] = useState<Boolean>(false);

  const notifySelectedSiteChanged = (state: UiState) => {
    const { siteId } = state.context;
    const value = siteId && !isEmpty(siteId) ? siteId : null;
    if (value !== selectedSiteId.getValue()) {
      selectedSiteId.next(value);
    }
  };

  const notifySelectedVehicleIdChanged = (state: UiState) => {
    const vehicleId = state.context.vehicleId ?? null;
    if (vehicleId !== selectedVehicleId.getValue()) {
      selectedVehicleId.next(vehicleId);
    }
  };

  const notifySelectedQueueIdChanged = (state: UiState) => {
    const { queueId } = state.context;
    const value = queueId && !isEmpty(queueId) ? queueId : null;
    if (value !== selectedQueueId.getValue()) {
      selectedQueueId.next(value);
    }
  };

  const notifySelectedPaddockIdChanged = (state: UiState) => {
    const { paddockId } = state.context;
    const value = paddockId && !isEmpty(paddockId) ? paddockId : null;
    if (value !== selectedPaddockId.getValue()) {
      selectedPaddockId.next(value);
    }
  };

  const notifyObservers = useCallback((state: UiState) => {
    notifySelectedSiteChanged(state);
    notifySelectedVehicleIdChanged(state);
    notifySelectedQueueIdChanged(state);
    notifySelectedPaddockIdChanged(state);
  }, []);

  // Subscribe to state changes to detect area/site, vehicle etc selection
  // We want to store this in a RxJS Observable (rather BehaviourSubject) -
  // to be able to use in conjunction with the grapqhl package Observables we use
  service.onTransition((state) => {
    if (state.changed) {
      notifyObservers(state);
    }
  });

  useEffect(() => {
    // Fallback to vehicle list instead of recovering vehicle view from persisted state
    const latestEventType = uiState.event.type;
    const previousEventType = uiState.history?.event.type;
    if (
      !["DISPATCH_VEHICLE", "SELECT_VEHICLE"].includes(latestEventType) &&
      previousEventType &&
      !["DISPATCH_VEHICLE", "SELECT_VEHICLE"].includes(previousEventType)
    ) {
      setPersistedState(uiState);
      notifyObservers(uiState);
    }
  }, [uiState, setPersistedState, notifyObservers]);

  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  // Need to check if the machine is mounted due to `useMachine` uses an effect internally

  return mounted ? (
    <UiStateProvider service={service}>{children}</UiStateProvider>
  ) : null;
};

export default UiMachineProvider;
