import React from "react";
import { ICommonIconProps } from "./CommonIconProps";

export const ErrorIcon = ({ className, style, testid }: ICommonIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      data-testid={testid}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.50019C5.85662 2.50019 2.49775 5.85906 2.49775 10.0024C2.49775 14.1458 5.85662 17.5047 10 17.5047C14.1434 17.5047 17.5023 14.1458 17.5023 10.0024C17.5023 5.85906 14.1434 2.50019 10 2.50019ZM1.25 10.0024C1.25 5.16995 5.16751 1.25244 10 1.25244C14.8325 1.25244 18.75 5.16995 18.75 10.0024C18.75 14.8349 14.8325 18.7524 10 18.7524C5.16751 18.7524 1.25 14.8349 1.25 10.0024Z"
        fill="#FF2340"
      />
      <path
        d="M9.25175 9.044V6.25H10.7367V9.044L10.5167 11.728H9.47175L9.25175 9.044ZM9.21875 12.564H10.7807V14.06H9.21875V12.564Z"
        fill="#FF2340"
      />
    </svg>
  );
};
export default ErrorIcon;
