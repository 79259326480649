import React, { useState, useEffect, useCallback } from "react";

import { Lab, SwitchSlider, Box } from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { isEqual } from "lodash";
import { t } from "ttag";

type InVehicleAndPersonalStandDownStates = {
  sendCommand: boolean;
  pending: boolean;
  success: boolean;
  failed: boolean;
};

type InVehicleAndPersonalStandDownActions = {
  inVehicleAndPersonalStandDown: () => void;
};

export const getInVehicleAndPersonalStandDownStates = (
  state: VehicleState
): InVehicleAndPersonalStandDownStates => {
  return {
    sendCommand: state.matches(
      "connection.online.command.inVehicleAndPersonalStandDown.sendCommand"
    ),
    pending: state.matches(
      "connection.online.command.inVehicleAndPersonalStandDown.pending"
    ),
    success: state.matches(
      "connection.online.command.inVehicleAndPersonalStandDown.success"
    ),
    failed: state.matches(
      "connection.online.command.inVehicleAndPersonalStandDown.failed"
    ),
  };
};

export const getInVehicleAndPersonalStandDownActions = (
  send: (event: VehicleEvent) => void
): InVehicleAndPersonalStandDownActions => {
  return {
    inVehicleAndPersonalStandDown: () =>
      send({ type: "COMMAND_IN_VEHICLE_AND_PERSONAL_STAND_DOWN" }),
  };
};

const getInVehicleAndPersonalStandDownStatus = ({
  sendCommand,
  pending,
  success,
}: InVehicleAndPersonalStandDownStates) => {
  if (sendCommand) {
    return t`Sending`;
  }

  if (pending) {
    return t`Wait`;
  }

  if (success) {
    return t`Success`;
  }

  return "";
};

type InVehicleAndPersonalStandDownProps = {
  states: InVehicleAndPersonalStandDownStates;
  actions: InVehicleAndPersonalStandDownActions;
};

const InVehicleAndPersonalStandDown: React.FC<InVehicleAndPersonalStandDownProps> = React.memo(
  ({ states, actions }) => {
    const [checked, setChecked] = useState(false);
    const { failed } = states;
    const callback = useCallback(() => {
      setChecked(true);
      actions.inVehicleAndPersonalStandDown();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (failed) {
        setChecked(false);
      }
    }, [actions, failed]);

    const handleChecked = callback;

    return (
      <Box position="relative">
        {failed && (
          <Box position="absolute" bottom="100%" width={1}>
            <Lab.Alert severity="error">
              <Lab.AlertTitle>{t`Unable to enter safe state`}</Lab.AlertTitle>
            </Lab.Alert>
          </Box>
        )}
        <SwitchSlider
          leftLabel={getInVehicleAndPersonalStandDownStatus(states)}
          rightLabel={t`Enter Safe State`}
          checked={checked}
          onChecked={handleChecked}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.states, nextProps.states)
);

export default InVehicleAndPersonalStandDown;
