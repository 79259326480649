import { StateNodeConfig } from "xstate";
import {
  VehicleContext,
  VehicleEvent,
} from "@src/machines/vehicle/vehicle.machine";
import { sendRelease, sendReleasePersonal } from "@ats/graphql";

// The hierarchical (recursive) schema for the states
export interface ReleaseToContinueStateSchema {
  states: {
    releasing: {
      states: {
        sendCommand: {
          states: {
            onDone: {};
            onError: {};
          };
        };
        success: {};
        failed: {};
      };
    };
    done: {};
  };
}

export const sendReleaseToContinue = () => async (context: VehicleContext) => {
  return (
    sendRelease(context.externalEquipmentReference),
    sendReleasePersonal(context.externalEquipmentReference)
  );
};

const ReleaseToContinueStates: StateNodeConfig<
  VehicleContext,
  ReleaseToContinueStateSchema,
  VehicleEvent
> = {
  id: "releaseToContinue",
  initial: "releasing",
  states: {
    releasing: {
      initial: "sendCommand",
      states: {
        sendCommand: {
          invoke: {
            src: "sendReleaseToContinue",
            onDone: {
              target: "success",
            },
            onError: {
              target: "failed",
              actions: (_context, event) => {
                // eslint-disable-next-line no-console
                console.error("Error sending release commands", event);
              },
            },
          },
        },
        success: {
          on: {
            DONE_VEHICLE_ACTION: "#releaseToContinue.done",
          },
        },
        failed: {
          on: {
            RETRY_VEHICLE_ACTION: "sendCommand",
            CONFIRM_ERROR_VEHICLE_ACTION: "#releaseToContinue.done",
          },
        },
      },
    },
    done: {
      type: "final",
    },
  },
};

export default ReleaseToContinueStates;
