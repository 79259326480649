import IEnvironment from "./IEnvironment";

const { environment } = window as Window &
  // eslint-disable-next-line no-undef
  typeof globalThis & { environment: IEnvironment };

const env = environment || {
  appSync: {
    endpoint: "",
    region: "",
  },
  env: "",
  devTools: true,
  keycloak: {
    url: "",
    clientId: "",
  },
};

export default env;
