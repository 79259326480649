import { useRef, useEffect, useMemo } from "react";
import { Subscription, useSubscription } from "use-subscription";
import { EventObject, Interpreter, State, Typestate } from "xstate";
import { throttle } from "lodash";

// This is a version of xstate/react/useService that accepts nullable input
// the hook subscribes to an interpreters state changes

// The original version does not accept nullable input and that made the application crash when
// having selected a vehicle and then switching area which made the vehicle disappear
// see: https://github.com/davidkpiano/xstate/blob/master/packages/xstate-react/src/index.ts

export function useThrottledService<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = any
>(
  service: Interpreter<TContext, any, TEvent, TTypestate>
): [
  State<TContext, TEvent, any, TTypestate>,
  Interpreter<TContext, any, TEvent, TTypestate>["send"],
  Interpreter<TContext, any, TEvent, TTypestate>
] {
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const subscription: Subscription<State<
    TContext,
    TEvent,
    any,
    TTypestate
  >> = useMemo(() => {
    return {
      getCurrentValue: () => service.state || service.initialState,
      subscribe: (callback: any) => {
        const throttledCallback = throttle(() => {
          if (isMountedRef.current) {
            callback();
          }
        }, 1000);

        const { unsubscribe } = service.subscribe((state) => {
          if (state.changed !== false) {
            throttledCallback();
          }
        });

        return unsubscribe;
      },
    };
  }, [service]);

  const subscriptionState = useSubscription(subscription);

  return [subscriptionState, service?.send, service];
}
