import { Degradations } from "@ats/graphql";

interface IEStopButtonDegradationStatus {
  eStopDegradationWithActiveFault: boolean;
  eStopDegradationWithHealedFault: boolean;
}
const eStopButtonDegradationStatus = (
  degradations: ReadonlyArray<Degradations> | null
): IEStopButtonDegradationStatus => {
  const isDegradationActive =
    degradations !== null &&
    degradations.length > 0 &&
    (degradations[0].degradations.includes(
      "BehvV2xHandlerEStopButtonsActive"
    ) ||
      degradations[0].degradations.includes(
        "BehvV2xHandlerEStopButtonsWithInvalidPosition"
      ));
  const isFaultActive =
    degradations !== null &&
    degradations.length > 0 &&
    (degradations[0].faults.includes("BehvV2xHandlerEStopButtonsActive") ||
      degradations[0].faults.includes(
        "BehvV2xHandlerEStopButtonsWithInvalidPosition"
      ));

  return {
    eStopDegradationWithActiveFault: isDegradationActive && isFaultActive,
    eStopDegradationWithHealedFault: isDegradationActive && !isFaultActive,
  };
};
export default eStopButtonDegradationStatus;
