import React from "react";

import { AbilityContext } from "@src/casl/Can";
import ability from "@src/casl/ability";

import { useUser } from "@src/hooks";

const AbilityProvider: React.FC = ({ children }) => {
  const { role } = useUser();

  return (
    <AbilityContext.Provider value={ability(role)}>
      {children}
    </AbilityContext.Provider>
  );
};

export default AbilityProvider;
