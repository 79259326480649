import React from "react";
import { isEqual } from "lodash";
import { Icons, ListItemIcon } from "@src/components";

import { VehiclesQuery_allEquipment as Vehicle } from "@src/graphql/schemaTypes";

export type LockIconProps = {
  status: Vehicle["status"];
};

const LockIcon = React.memo(
  ({ status }: LockIconProps) => {
    return (
      <ListItemIcon>
        {status?.owner && <Icons.Lock fontSize="small" color="disabled" />}
      </ListItemIcon>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.status, nextProps.status)
);

export default LockIcon;
