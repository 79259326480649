import React, { useState } from "react";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { VehiclesQuery_allEquipment_status_staff as Staff } from "@src/graphql/schemaTypes";
import { t } from "ttag";
import VehicleActionButtonContainer from "../../VehicleActionButton.container";

interface PersonalOwnershipStates {
  locking: boolean;
  releasing: boolean;
}

interface PersonalOwnershipActions {
  personalOwnership: () => void;
  releasePersonalOwnership: () => void;
  done: () => void;
}

export const getPersonalOwnershipStates = (
  state: VehicleState
): PersonalOwnershipStates => {
  return {
    locking: state.matches(
      "connection.online.interaction.personalOwnership.locking"
    ),
    releasing: state.matches(
      "connection.online.interaction.releasePersonalOwnership.releasing"
    ),
  };
};

export const getPersonalOwnershipActions = (
  send: (event: VehicleEvent) => void,
  done: () => void
): PersonalOwnershipActions => ({
  personalOwnership: () => send({ type: "SELECT_PERSONAL_OWNERSHIP" }),
  releasePersonalOwnership: () =>
    send({ type: "SELECT_RELEASE_PERSONAL_OWNERSHIP" }),
  done: () => {
    done();
  },
});

type PersonalOwnershipProps = {
  states: PersonalOwnershipStates;
  actions: PersonalOwnershipActions;
  lockedBy: Promise<Staff> | null;
};

const PersonalOwnership: React.FC<PersonalOwnershipProps> = ({
  states,
  actions,
  lockedBy,
}) => {
  const [lockedByData, setLockedByData] = useState<Staff | null>(null);
  // eslint-disable-next-line no-unused-expressions
  lockedBy?.then((staff) => setLockedByData(staff));

  const buttonLabel = lockedByData !== null ? t`Unlock` : t`Lock`;
  /* eslint-disable no-nested-ternary */
  const lockInfoText =
    lockedByData === null
      ? t`No lock (any user can send mission)`
      : lockedByData?.isMe
      ? t`Locked (only you can send mission)`
      : t`Locked (by ${lockedByData?.firstName} ${lockedByData?.lastName})`;

  return (
    <VehicleActionButtonContainer
      action="PERSONAL_OWNERSHIP"
      disabled={lockedByData !== null && !lockedByData?.isMe}
      onClick={
        lockedByData === null
          ? actions.personalOwnership
          : actions.releasePersonalOwnership
      }
      testId="lock-button"
      labelTitle={t`Control`}
      buttonText={t`${buttonLabel}`}
      loading={states.locking || states.releasing}
      labelSubTitle={t`${lockInfoText}`}
    />
  );
};

export default PersonalOwnership;
