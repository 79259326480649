import { DefaultTheme as _DefaultTheme } from "styled-components";
import { createMuiTheme } from "@material-ui/core";

// This export is to fix a storybook bug
// improve when possible https://github.com/webpack/webpack/issues/7378
export type DefaultTheme = _DefaultTheme;

const fontFamilies = {
  default: ["Scania Sans", "sans-serif"].join(","),
  heading: ["Scania Sans Headline", "sans-serif"].join(","),
};

const colors = {
  brand: {
    blue: "rgb(4, 30, 66)",
    white: "rgb(250, 250, 250)",
    red: "rgb(214, 0, 28)",
  },
  primary: {
    lightGrey: "rgb(200, 201, 199)",
    mediumGrey: "rgb(151, 153, 155)",
    darkGrey: "rgb(83, 86, 90)",
    black: "rgb(45, 41, 38)",
  },
  secondary: {
    orange: "rgb(227, 82, 5)",
    beige: "rgb(206, 184, 136)",
    paleGreen: "rgb(148, 165, 150)",
    green: "rgb(44, 82, 52)",
  },
  app: {
    color1: "rgb(68, 134, 145)",
    color2: "rgb(94, 56, 133)",
    color3: "rgb(0, 93, 109)",
    color4: "rgb(225, 169, 62)",
    color5: "rgb(137, 106, 115)",
    color6: "rgb(93, 167, 169)",
    color7: "rgb(96, 178, 108)",
  },
};

const inputMinHeight = "3.5rem";

const theme: DefaultTheme = createMuiTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: colors.brand.blue,
      contrastText: colors.brand.white,
      light: "",
      dark: "",
    },
    secondary: {
      main: colors.secondary.orange,
    },
    text: {
      primary: colors.primary.mediumGrey,
      secondary: colors.brand.blue,
    },
    background: {
      default: colors.brand.white,
      paper: colors.brand.white,
    },
    error: {
      main: colors.brand.red,
    },
    success: {
      main: colors.secondary.paleGreen,
    },
    info: {
      main: colors.primary.mediumGrey,
    },
    common: {
      white: colors.brand.white,
      black: colors.primary.black,
    },
  },
  typography: {
    fontFamily: fontFamilies.default,
    h1: {
      fontFamily: fontFamilies.heading,
      fontSize: 30,
      fontWeight: "bold",
      lineHeight: "90%",
      textTransform: "uppercase",
      color: colors.brand.blue,
    },
    h2: {
      fontFamily: fontFamilies.default,
      fontSize: 17,
    },
    h3: {
      fontFamily: fontFamilies.default,
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: "90%",
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: fontFamilies.default,
      fontSize: 14,
      lineHeight: "160%",
    },
    subtitle1: {
      fontFamily: fontFamilies.heading,
      fontSize: 16,
      lineHeight: "120%",
      textTransform: "uppercase",
    },
    subtitle2: {
      fontFamily: fontFamilies.heading,
      fontSize: 16,
      lineHeightStep: "120%",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: fontFamilies.default,
      fontSize: 11,
    },
  },
  overrides: {
    MuiAvatar: {
      circle: {
        backgroundColor: "transparent",
      },
      root: {
        fontSize: "1rem",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: colors.primary.lightGrey,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: "none",
      },
      contained: {
        fontFamily: fontFamilies.default,
        fontSize: 15,
        minHeight: inputMinHeight,
      },
      outlined: {
        borderColor: "black",
        color: "black",
        fontFamily: fontFamilies.default,
        fontSize: 15,
      },
    },
    MuiFab: {
      extended: {
        borderRadius: "50%",
        backgroundColor: colors.brand.red,
        "&:hover": {
          backgroundColor: colors.brand.red,
        },
        "@media (hover: none)": {
          backgroundColor: colors.brand.red,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        minHeight: inputMinHeight,
        boxSizing: "border-box",
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.primary.mediumGrey,
        fontWeight: "bold",
      },
      outlined: {
        transform: "none",
        position: "static",
        marginBottom: ".5rem",
      },
    },
    MuiListItemText: {
      root: {
        fontFamily: fontFamilies.default,
        fontSize: 14,
        fontWeight: 400,
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: "calc(100vh)",
      },
      list: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiAccordionSummary: {
      root: {
        paddingLeft: 0,
      },
      content: {
        margin: 0,
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiAccordion: {
      root: {
        position: "inherit",
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    // MuiPopover: {
    //   paper: {
    //     position: "initial",
    //   },
    // },
  },
});

export default theme;
